import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const DashboardPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "dashboard" */'./dashboard.page.js'),
	modules: ['./dashboard.page.js'],
	webpack: () => [require.resolveWeak('./dashboard.page.js')],
	loading: Loading,
});

export default DashboardPageAsync;
