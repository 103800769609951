import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const ActiveAccountEmailModalAsync = Loadable({
	loader: () => import(/* webpackChunkName: "active-account-email" */ './active-account-email.js'),
	modules: ['./active-account-email.js'],
	webpack: () => [require.resolveWeak('./active-account-email.js')],
	loading: Loading,
});

export default ActiveAccountEmailModalAsync;
