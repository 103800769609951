import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const ChallengeListPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "challenge-list" */'./challenge-list.page.js'),
	modules: ['./challenge-list.page.js'],
	webpack: () => [require.resolveWeak('./challenge-list.page.js')],
	loading: Loading,
});

export default ChallengeListPageAsync;
