import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const UserPaymentsPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "user-payments" */ './user-payments.page.js'),
	modules: ['./user-payments.page.js'],
	webpack: () => [require.resolveWeak('./user-payments.page.js')],
	loading: Loading,
});

export default UserPaymentsPageAsync;
