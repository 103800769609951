import React from 'react';
import cn from 'classnames';
import './image-avatar.scss';

const ImageAvatar = ({
	picture, className, initials, alt, width, height,
}) => (
	<div
		className={cn('picture-wrapper', className, { emptyPicture: !picture })}
		style={{ width, height }}
	>
		{picture ? (
			<div className="img" style={{ backgroundImage: `url(${picture})` }} title={alt} />
		) : (
			<span className="initials">{initials}</span>
		)}
	</div>
);

export default ImageAvatar;
