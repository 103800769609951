import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const SignUpPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "sign-up" */ './sign-up.page.js'),
	modules: ['./sign-up.page.js'],
	webpack: () => [require.resolveWeak('./sign-up.page.js')],
	loading: Loading,
});

export default SignUpPageAsync;
