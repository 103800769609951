import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const ContactsPageAsync = Loadable({
	loader: () => import('./contacts.page.js'),
	modules: ['./contacts.page.js'],
	webpack: () => [require.resolveWeak('./contacts.page.js')],
	loading: Loading,
});

export default ContactsPageAsync;
