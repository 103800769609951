import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const PaymentSuccessfulPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "payment-successful" */'./payment-successful.page.js'),
	modules: ['./payment-successful.page.js'],
	webpack: () => [require.resolveWeak('./payment-successful.page.js')],
	loading: Loading,
});

export default PaymentSuccessfulPageAsync;
