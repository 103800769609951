import React, { Component, Fragment } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { inject, observer } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import cn from 'classnames';
import onClickOutside from 'react-onclickoutside';
import { toast } from 'react-toastify';
import Media from 'react-media';
import UserWidget from '../components/user-widget.js';
import Utils from '../components/shared/util.js';
import ChatNotifications from './chat-notifications.component.js';
import UserService from '../shared/user.service.js';
import LayoutService from './layout.service.js';
import './navbar.component.scss';


const NavLogoWrapper = ({ children }) => (
	<div className="col-xs-6 col-md-3 start-xs middle-xs">
		{children}
	</div>
);

const NavLogo = ({ path }) => (
	<Link to="/" className="logo-wrapper">
		<img
			className="logo"
			src={process.env.REACT_APP_CMS_URL + path}
			alt=""
		/>
	</Link>
);

const NavListWrapper = inject('authService')(({ authService, children }) => (
	<div className={cn('col-xs-6 col-md-9 middle-xs end-xs',
		{
			'between-md': authService.isLoggedIn,
		})}
	>
		{children}
	</div>
));

const NavList = inject('generalStore')(
	observer(({ children, generalStore }) => (
		<nav className={cn('nav-wrapper', { open: generalStore.navbarOpen })}>
			<ul className="nav-list">{children}</ul>
		</nav>
	)),
);

const isActive = match => match && match.isExact;

const NavItem = withRouter(inject('generalStore', 'languageStore')(
	observer(({
		path, childPath, label, isButton, generalStore, location, languageStore, hasChildren, children,
	}) => {
		// unwrap the language so it updates when the language is changed
		// eslint-disable-next-line no-unused-vars
		const { language } = languageStore;
		return (
			<li className={cn('nav-item', { button: isButton })}>
				{ hasChildren ? (
					<DropdownNav label={label}>{children}</DropdownNav>
				) : (
					<Link
						to={path}
						onClick={() => { generalStore.closeNavbar(); }}
						isActive={isActive}
						className={cn(
							{ hasActiveChild: location.pathname.startsWith(childPath) },
						)}
					>
						<FormattedMessage id={label}>
							{txt => <Fragment>{txt}</Fragment>}
						</FormattedMessage>
					</Link>
				)}
			</li>
		);
	}),
));

// eslint-disable-next-line react/no-multi-comp
class DropdownNavInternal extends React.Component {
	state = {
		isOpen: false,
	};

	handleClickOutside = (evt) => {
		this.setState({
			isOpen: false,
		});
	};

	toggleDropdown = () => {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	};

	render() {
		const { label, children } = this.props;
		const { isOpen } = this.state;

		const childrenWithProps = React.Children.map(children, child => React.cloneElement(child, { toggleDropdown: this.toggleDropdown }));

		return (
			<div>
				<button className={cn({ isOpen }, 'label')} type="button" onClick={this.toggleDropdown}>
					<FormattedMessage id={label}>
						{txt => <Fragment>{txt}</Fragment>}
					</FormattedMessage>
				</button>
				<div className={cn({ isOpen }, 'dropdown-nav-wrapper')}>
					<div className="dropdown-nav">{childrenWithProps}</div>
				</div>
			</div>
		);
	}
}

const DropdownNav = onClickOutside(DropdownNavInternal);

const DropdownNavItem = withRouter(inject('generalStore', 'languageStore')(
	observer(({
		path,
		childPath,
		label,
		isButton,
		generalStore,
		location,
		languageStore,
		external,
		toggleDropdown,
	}) => {
		// unwrap the language so it updates when the language is changed
		// eslint-disable-next-line no-unused-vars
		const { language } = languageStore;
		return (
			<div className={cn('nav-item', { button: isButton })}>
				{ external ? (
					<a
						href={path}
						target="_blank"
						rel="noopener noreferrer"
						onClick={() => { toggleDropdown(); generalStore.closeNavbar(); }}
					>
						<FormattedMessage id={label}>
							{txt => <Fragment>{txt}</Fragment>}
						</FormattedMessage>
					</a>
				) : (
					<Link
						to={path}
						onClick={() => { generalStore.closeNavbar(); }}
						isActive={isActive}
						className={cn(
							{ hasActiveChild: location.pathname.startsWith(childPath) },
						)}
					>
						<FormattedMessage id={label}>
							{txt => <Fragment>{txt}</Fragment>}
						</FormattedMessage>
					</Link>
				)}
			</div>
		);
	}),
));

const NavMenuButton = inject('generalStore')(
	observer(({ generalStore }) => (
		<div className={cn('nav-menu-button-wrapper', { open: generalStore.navbarOpen })}>
			<button
				className={cn('nav-menu-button', { open: generalStore.navbarOpen })}
				onClick={generalStore.toggleNavbar}
			>
				<span />
				<span />
				<span />
				<span />
				<span />
				<span />
			</button>
		</div>
	)),
);


const NavProfile = ({
	switchUserMenu, toggleSwitchUserMenu, closeSwitchUserMenu,
}) => (
	<UserWidget
		switchUserMenu={switchUserMenu}
		toggleSwitchUserMenu={toggleSwitchUserMenu}
		closeSwitchUserMenu={closeSwitchUserMenu}
	/>
);


const UserNavbarItems = withRouter(inject('userStore')(
	observer(({ userStore }) => {
		const { seekers, solvers } = userStore;

		if (userStore.isFetchingProfile) return null;

		if (seekers.length === 0 && solvers.length === 0) {
			return (
				<Fragment>
					<NavItem
						path="/"
						childPath="/challenges"
						label="navbar.challenges"
					/>
					<NavItem
						path="/account"
						childPath="/account"
						label="navbar.complete-your-profile"
						isButton
					/>
				</Fragment>
			);
		}

		return (
			<Fragment>
				<NavItem
					path="/"
					childPath="/challenges"
					label="navbar.challenges"
				/>
				<NavItem
					path="/dashboard"
					childPath="/dashboard"
					label="navbar.dashboard"
				/>

				{ userStore.profile.type === 'SEEKER' && (
					<NavItem
						path="/solutions"
						childPath="/solutions"
						label="navbar.solutions"
					/>
				)}

				<NavItem
					path="/contacts"
					childPath="/contacts"
					label="navbar.contacts"
				/>
			</Fragment>
		);
	}),
));

const NavSeparator = () => (
	<div className="nav-separator" />
);

const NavSeparatorHorizontal = () => (
	<div className="nav-separator-horizontal-wrapper">
		<div className="nav-separator-horizontal" />
	</div>
);

const NavSeparatorProfile = inject('userStore')(
	observer(({
		userStore, switchUserMenu, toggleSwitchUserMenu, closeSwitchUserMenu,
	}) => (
		<div className="nav-notifications-profile">
			{!userStore.isFetching && userStore.user.isActive
			&& (
				<Fragment>
					<ChatNotifications />
					<NavSeparator />
				</Fragment>
			)}

			<Media
				query="(min-width: 992px)"
				render={() => (
					<NavProfile
						switchUserMenu={switchUserMenu}
						toggleSwitchUserMenu={toggleSwitchUserMenu}
						closeSwitchUserMenu={closeSwitchUserMenu}
					/>
				)}
			/>
		</div>
	)),
);


const NavbarWrapper = ({ children }) => (
	<div className="site-navbar">
		<div className="container container-fluid">
			<div className="row">
				{children}
			</div>
		</div>
	</div>
);


class Navbar extends Component {
	state = {
		switchUserMenu: false,
	};

	componentDidMount() {
		const { authService, generalStore } = this.props;
		const { generalData } = generalStore;

		if (authService.isLoggedIn) {
			const { userStore } = this.props;

			userStore.isFetchingProfile = true;

			if (generalData.length === 0) {
				this.fetchData();
			}

			Promise.all([
				UserService.getAccountSeekers(),
				UserService.getAccountSolvers(),
			])
				.then(
					([{ contentSeekers }, { contentSolvers }]) => {
						userStore.storeSeekers(contentSeekers);
						userStore.storeSolvers(contentSolvers);

						userStore.isFetchingProfile = false;
					},
				)
				.catch((error) => {
					const { intl } = this.props;

					Utils.showErrorMessageAlert(error, toast.TYPE.ERROR, intl);
				});
		}
	}

	fetchData = () => {
		const { languageStore, generalStore } = this.props;
		const { language } = languageStore;

		LayoutService.getGeneralData(language.value)
			.then(({ data }) => {
				generalStore.storeGeneralData(data);
			})
			.catch((error) => {
				const { intl } = this.props;

				Utils.showErrorMessageAlert(error, toast.TYPE.ERROR, intl);
			});
	};

	toggleSwitchUserMenu = () => {
		const { switchUserMenu } = this.state;

		if (!switchUserMenu) {
			Promise.all([
				UserService.getAccountSeekers(),
				UserService.getAccountSolvers(),
			])
				.then(
					([{ contentSeekers }, { contentSolvers }]) => {
						const { userStore } = this.props;

						userStore.storeSeekers(contentSeekers);
						userStore.storeSolvers(contentSolvers);

						this.setState(prevState => ({
							switchUserMenu: !prevState.switchUserMenu,
						}));
					},
				)
				.catch((error) => {
					const { intl } = this.props;

					Utils.showErrorMessageAlert(error, toast.TYPE.ERROR, intl);
				});
		} else {
			this.setState(prevState => ({
				switchUserMenu: !prevState.switchUserMenu,
			}));
		}
	};

	closeSwitchUserMenu = () => {
		this.setState({
			switchUserMenu: false,
		});
	};


	render() {
		const { switchUserMenu } = this.state;
		const { authService, generalStore } = this.props;
		const { generalData } = generalStore;

		// unwrap the language so it updates when the language is changed
		// eslint-disable-next-line no-unused-vars
		const { location, location: { search } } = this.props;

		return (
			<NavbarWrapper>
				<NavLogoWrapper>
					{generalData
							&& generalData.logo
							&& generalData.logo.path && (
							<NavLogo path={generalData.logo.path} />
					)}
				</NavLogoWrapper>
				<NavListWrapper>
					<NavList>
						{authService.isLoggedIn && (
							<Fragment>
								<UserNavbarItems />
								<Media
									query="(max-width: 991.98px)"
									render={() => (
										<Fragment>
											<NavSeparatorHorizontal />
											<NavProfile
												switchUserMenu={switchUserMenu}
												toggleSwitchUserMenu={this.toggleSwitchUserMenu}
												closeSwitchUserMenu={this.closeSwitchUserMenu}
											/>
										</Fragment>
									)}
								/>
							</Fragment>
						)}

						{!authService.isLoggedIn && (
							<Fragment>
								<NavItem
									path="/"
									childPath="/challenges"
									label="navbar.challenges"
								/>
								<NavItem
									path="/how-it-works"
									childPath="/how-it-works"
									label="navbar.how_it_works"
									hasChildren
								>
									<DropdownNavItem
										path="https://inocrowd.com.pt/how-it-works/seeker"
										label="navbar.SEEKER"
										external
									/>
									<DropdownNavItem
										path="https://inocrowd.com.pt/how-it-works/solver"
										label="navbar.SOLVER"
										external
									/>
									<DropdownNavItem
										path="https://inocrowd.com.pt/how-it-works/faq"
										label="navbar.faq"
										external
									/>
								</NavItem>
								<NavItem
									path="/sign-in"
									childPath="/sign-in"
									label="navbar.sign_in"
								/>
								<NavItem
									path={search ? `/sign-up${search}` : '/sign-up'}
									childPath={search ? `/sign-up${search}` : '/sign-up'}
									label="navbar.get_started"
									isButton
								/>
							</Fragment>
						)}
					</NavList>

					{	authService.isLoggedIn && (
						<NavSeparatorProfile
							switchUserMenu={switchUserMenu}
							toggleSwitchUserMenu={this.toggleSwitchUserMenu}
							closeSwitchUserMenu={this.closeSwitchUserMenu}
						/>
					)}

					<NavMenuButton />
				</NavListWrapper>
			</NavbarWrapper>
		);
	}
}


export default withRouter(injectIntl(inject('authService', 'userStore', 'generalStore')(
	observer(Navbar),
)));
