import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const AcceptTermsModalAsync = Loadable({
	loader: () => import(/* webpackChunkName: "accept-terms.modal" */ './accept-terms.modal.js'),
	modules: ['./accept-terms.modal.js'],
	webpack: () => [require.resolveWeak('./accept-terms.modal.js')],
	loading: Loading,
});

export default AcceptTermsModalAsync;
