import axios from 'axios';
import ContactsModel from './contacts-model.js';

class ContactsService {
	getContacts({
		sort = 'asc', page = 0, size = 12, search = '',
	} = {}) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/api/contacts/all`,
				{
					params: {
						search, page, size, sort: `contact.firstName,${sort}`,
					},
				})
			.then(res => ({
				contacts: res.data.content.map(contact => new ContactsModel(contact)),
				page: res.data.number,
				size: res.data.size,
				totalPages: res.data.totalPages,
			}));
	}

	getContactById(id) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/api/contacts/${id}`)
			.then(res => res.data);
	}

	getContactsSuggestions({ text = '', size = 10 } = {}) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/api/contacts/searchauto`,
				{ params: { text, size } })
			.then(res => res.data);
	}

	getChatContacts() {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/chat/contacts`)
			.then(res => res.data);
	}

	getMessages({
		id, anonymous, page = 0, size = 50, timestamp,
	} = {}) {
		let url;
		let params;

		if (anonymous) {
			url = `${process.env.REACT_APP_API_URL}/chat/challenge/room/${id}`;

			params = {
				page, size, timestamp,
			};
		} else {
			url = `${process.env.REACT_APP_API_URL}/chat/history`;

			params = {
				userId: id, page, size, timestamp,
			};
		}

		return axios
			.get(url,
				{
					params,
				})
			.then(res => ({
				messagesList: res.data.content,
				totalElements: res.data.totalElements,
			}));
	}

	getUnreadMessages() {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/chat/unread`)
			.then(res => res.data);
	}

	getAnonymousUnreadMessages() {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/chat/challenge/unread`)
			.then(res => res.data);
	}

	markMessagesAsRead(userId) {
		return axios
			.put(`${process.env.REACT_APP_API_URL}/chat/read?userId=${userId}`)
			.then(res => res);
	}

	markAnonymousMessagesAsRead(roomId) {
		return axios
			.put(`${process.env.REACT_APP_API_URL}/chat/challenge/room/${roomId}/read`)
			.then(res => res);
	}

	openAnonymousChat(challengeId) {
		return axios
			.put(`${process.env.REACT_APP_API_URL}/chat/challenge/${challengeId}`)
			.then(res => res.data);
	}
}

export default new ContactsService();
