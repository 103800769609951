import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const EmailVerificationModalAsync = Loadable({
	loader: () => import(/* webpackChunkName: "verify-account-email" */ './verify-account-email.js'),
	modules: ['./verify-account-email.js'],
	webpack: () => [require.resolveWeak('./verify-account-email.js')],
	loading: Loading,
});

export default EmailVerificationModalAsync;
