import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { NavLink as Link } from 'react-router-dom';
import Select from 'react-select';

import LayoutService from './layout.service.js';
import localizedItems from '../shared/localization-items.js';
import Utils from '../components/shared/util.js';

import icFacebook from '../assets/images/footer/ic-facebook.svg';
import icInstagram from '../assets/images/footer/ic-instagram.svg';
import icLinkedin from '../assets/images/footer/ic-linkedin.svg';
import icTwitter from '../assets/images/footer/ic-twitter.svg';
import icYoutube from '../assets/images/footer/ic-youtube.svg';
import illuFooter from '../assets/images/footer/illu-footer.svg';

import './footer.scss';

const FooterPortal = () => <div id="footer-portal" />;

const FooterLogo = ({ path }) => (
	<Link to="/">
		<img
			className="logo"
			src={process.env.REACT_APP_CMS_URL + path}
			alt=""
		/>
	</Link>
);

const FooterNav = ({ children }) => (
	<div className="navigation">
		<h5 className="column-title">Inocrowd</h5>
		<ul className="navigation-list">{children}</ul>
	</div>
);

const FooterNavItem = ({ path, label, newTab }) => (
	<li className="navigation-item">
		{
			newTab
				? (
					<a href={path} target="_blank" rel="noopener noreferrer">
						<FormattedMessage id={label}>
							{txt => <Fragment>{txt}</Fragment>}
						</FormattedMessage>
					</a>
				)
				:		(
					<Link to={path}>
						<FormattedMessage id={label}>
							{txt => <Fragment>{txt}</Fragment>}
						</FormattedMessage>
					</Link>
				)}
	</li>
);

const FooterContactAddress = ({ addresses }) => (
	<div className="addresses">
		<h5 className="column-title">
			<FormattedMessage id="footer.address.title">
				{txt => <Fragment>{txt}</Fragment>}
			</FormattedMessage>
		</h5>
		<ul className="addresses-list">
			<a
				href="https://goo.gl/maps/2LEvBjZerLu"
				target="_blank"
				rel="noopener noreferrer"
			>
				{addresses.map(item => (
					<FooterContactAddressItem item={item} key={item._id} />
				))}
			</a>
		</ul>
	</div>
);

const FooterContactAddressItem = ({ item }) => (
	<li className="address-item">
		<p dangerouslySetInnerHTML={{ __html: item.description }} />
	</li>
);

const FooterContactPhone = ({ phones }) => (
	<div className="phones">
		<h5 className="column-title">
			<FormattedMessage id="footer.phone.title">
				{txt => <Fragment>{txt}</Fragment>}
			</FormattedMessage>
		</h5>
		<ul className="addresses-list">
			{phones.map(item => (
				<FooterContactPhoneItem item={item} key={item._id} />
			))}
		</ul>
	</div>
);

const FooterContactPhoneItem = ({ item }) => (item.phoneNumber ? (
	<li className="phone-item">
		<a href={`tel:${item.phoneNumber.replace(/\s/g, '')}`}>
			{item.phoneNumber}
		</a>
	</li>
) : null);

const I18N = inject('languageStore')(observer(({ languageStore }) => (
	<div
		className="i18n"
		style={{ width: `${11 * languageStore.label.length + 50}px` }}
	>
		<Select
			className="select"
			classNamePrefix="select"
			name="localization"
			value={languageStore.language}
			onChange={languageStore.changeLanguage}
			clearable={false}
			searchable={false}
			options={localizedItems}
			autosize={false}
		/>
	</div>
)));

const PrivacyAndTerms = inject('languageStore')(({ languageStore: { region: lang } }) => (
	<div className="privacy-and-terms">
		<p>
			<a
				href={`${process.env.REACT_APP_API_URL}/api/legaldocument/download?documentType=data.privacy.policy&lang=${lang}`}
				target="_blank"
				rel="noopener noreferrer"
			>
				<FormattedMessage id="footer.privacy.label">
					{txt => <Fragment>{txt}</Fragment>}
				</FormattedMessage>
			</a>
			<FormattedMessage id="footer.and.label">
				{txt => <Fragment>{txt}</Fragment>}
			</FormattedMessage>
			<a
				href={`${process.env.REACT_APP_API_URL}/api/legaldocument/download?documentType=terms.of.use&lang=${lang}`}
				target="_blank"
				rel="noopener noreferrer"
			>
				<FormattedMessage id="footer.terms.label">
					{txt => <Fragment>{txt}</Fragment>}
				</FormattedMessage>
			</a>
		</p>
	</div>
));


const Faqs = () => (
	<div className="faqs">
		<a
			href="https://inocrowd.com.pt/how-it-works/faq"
			target="_blank"
			rel="noopener noreferrer"
		>
			FAQs
		</a>
	</div>
);

const SocialLinks = () => (
	<ul className="social-links">
		<li className="social-link-item">
			<a
				href="https://www.facebook.com/InoCrowd/"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img className="facebook" src={icFacebook} alt="Facebook icon" />
			</a>
		</li>
		<li className="social-link-item">
			<a
				href="https://www.linkedin.com/company/inocrowd"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img className="linkedin" src={icLinkedin} alt="Linkedin icon" />
			</a>
		</li>
		<li className="social-link-item">
			<a
				href="https://twitter.com/InoCrowd"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img className="twitter" src={icTwitter} alt="Twitter icon" />
			</a>
		</li>
		<li className="social-link-item">
			<a
				href="https://www.instagram.com/inocrowd"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img className="instagram" src={icInstagram} alt="Instagram icon" />
			</a>
		</li>
		<li className="social-link-item">
			<a
				href="https://www.youtube.com/channel/UC8JotJA9Txz_6NQXWuEwdvQ"
				target="_blank"
				rel="noopener noreferrer"
			>
				<img className="youtube" src={icYoutube} alt="Youtube icon" />
			</a>
		</li>
	</ul>
);

const FooterCopyright = () => (
	<p className="copyright">
		{`Inocrowd © ${new Date().getFullYear()} `}
		<FormattedMessage id="footer.copyright">
			{txt => <Fragment>{txt}</Fragment>}
		</FormattedMessage>
	</p>
);


class Footer extends Component {
	static prefetchData = async language => Promise.all([
		LayoutService.getGeneralData(language),
		LayoutService.getContactsData(language),
	]);

	componentDidMount() {
		const { generalStore } = this.props;
		const { generalData, addresses, phones } = generalStore;

		if (
			generalData.length === 0
			|| addresses.length === 0
			|| phones.length === 0
		) {
			this.fetchData();
		}
	}

	componentDidUpdate(prevProps) {
		if (this.props.intl.locale !== prevProps.intl.locale) {
			this.fetchData();
		}
	}

	fetchData() {
		const { languageStore, generalStore } = this.props;
		const { language } = languageStore;

		Footer.prefetchData(language)
			.then(([{ data }, { addressesList, phonesList }]) => {
				generalStore.storeGeneralData(data);
				generalStore.storeAddresses(addressesList);
				generalStore.storePhones(phonesList);
			})
			.catch((error) => {
				const { intl } = this.props;

				Utils.showErrorMessageAlert(error, toast.TYPE.ERROR, intl);
			});
	}

	render() {
		const { generalStore } = this.props;
		const { generalData, addresses, phones } = generalStore;

		return (
			<Fragment>
				<footer className="site-footer">
					<div className="container container-fluid">
						<div className="row">
							<div className="col-xs-12 col-md-3 start-xs top-xs">
								{generalData
								&& generalData.logo
								&& generalData.logo.path && (
									<FooterLogo path={generalData.logo.path} />
								)}
							</div>
							<div className="col-xs-12 col-md-9 start-xs top-xs gutterless">
								<div className="row">
									<div className="col-xs-6 col-md-3">
										<FooterNav>
											<FooterNavItem
												path="https://inocrowd.com.pt/about/who-are-we"
												label="footer.navigation.about.whoAreWe"
												newTab
											/>
											<FooterNavItem
												path="https://inocrowd.com.pt/news"
												label="footer.navigation.news"
												newTab
											/>
											<FooterNavItem
												path="https://inocrowd.com.pt/how-it-works/seeker"
												label="footer.navigation.howItWorks.seeker"
												newTab
											/>
											<FooterNavItem
												path="https://inocrowd.com.pt/how-it-works/solver"
												label="footer.navigation.howItWorks.solver"
												newTab
											/>
											<FooterNavItem
												path="https://inocrowd.com.pt/success-stories"
												label="footer.navigation.successStories"
												newTab
											/>
											<FooterNavItem
												path="https://inocrowd.com.pt/careers"
												label="footer.navigation.careers"
												newTab
											/>
											<FooterNavItem
												path="https://inocrowd.com.pt/partners"
												label="footer.navigation.partners"
												newTab
											/>
										</FooterNav>
									</div>
									<div className="col-xs-4 col-md-3">
										{addresses && <FooterContactAddress addresses={addresses} />}
									</div>
									<div className="col-xs-12 col-md-3">
										{phones && <FooterContactPhone phones={phones} />}
									</div>
									<div className="col-xs-6 col-md-3 language-and-terms">
										<I18N />
										<PrivacyAndTerms />
										<Faqs />
										<SocialLinks />
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr />
					<div className="container container-fluid">
						<div className="row">
							<div className="col-xs-6 col-md-12 center-md bottom-xs">
								<FooterCopyright year={new Date().getFullYear()} />
							</div>
							<div className="col-xs-12 col-md-12 footer-illustration-sector">
								<img
									className="footer-illustration"
									src={illuFooter}
									alt=""
									aria-hidden
								/>
							</div>
						</div>
					</div>
				</footer>
				<FooterPortal />
			</Fragment>
		);
	}
}

export default injectIntl(inject('generalStore', 'languageStore')(observer(Footer)));
