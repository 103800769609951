class ContactsModel {
	id = '';
	firstName = '';
	lastName = '';
	email = '';
	phoneNumber = '';
	avatar = '';
	country = '';
	online = '';
	note = '';
	organisations = [];

	constructor({
		id = '',
		firstName = '',
		lastName = '',
		email = '',
		phoneNumber = '',
		avatar = '',
		country = '',
		online = '',
		note = '',
		organisations = [],
	}) {
		this.id = id;
		this.firstName = firstName;
		this.lastName = lastName;
		this.email = email;
		this.phoneNumber = phoneNumber;
		this.avatar = avatar;
		this.country = country;
		this.online = online;
		this.note = note;
		this.organisations = organisations;
	}
}

export default ContactsModel;
