import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const ContactsMessagesPageAsync = Loadable({
	loader: () => import('./contacts-messages.page.js'),
	modules: ['./contacts-messages.page.js'],
	webpack: () => [require.resolveWeak('./contacts-messages.page.js')],
	loading: Loading,
});

export default ContactsMessagesPageAsync;
