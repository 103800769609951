import React from 'react';
import { Redirect, Route, withRouter } from 'react-router';
import { inject } from 'mobx-react';

function withSecuredRoute(WrappedComponent, redirectTo = 'sign-in') {
	return inject('authService')(props => (
		<React.Fragment>
			{!props.authService.isLoggedIn && <Redirect to={redirectTo} push />}
			{props.authService.isLoggedIn && <WrappedComponent />}
		</React.Fragment>
	));
}

const SecuredRoute = withRouter(inject('authService', 'userStore')(({
	authService,
	location,
	redirectTo = `/sign-in?redirect=${location.pathname}`,
	userStore,
	component: Component,
	...rest
}) => {
	if (!authService.isLoggedIn
		&& userStore.user.userLoggedOut
		&& userStore.user.logoutLocationKey === location.key) {
		return <Redirect to="/" push />;
	}

	if (!authService.isLoggedIn) return <Redirect to={redirectTo} push />;

	return <Route {...rest} render={props => <Component {...props} />} />;
}));


const AdminSecuredRoute = withRouter(inject('authService', 'userStore')(({
	authService,
	location,
	redirectTo = `/sign-in?redirect=${location.pathname}`,
	userStore,
	component: Component,
	...rest
}) => {
	if (!authService.isLoggedIn
		&& userStore.user.userLoggedOut
		&& userStore.user.logoutLocationKey === location.key) {
		return <Redirect to="/" push />;
	}

	if (!authService.isLoggedIn) return <Redirect to={redirectTo} push />;

	if (userStore.isFetching) {
		return null;
	}

	if (userStore.user && userStore.user.roles
		&& (userStore.user.roles.includes('SUPER_ADMIN') || userStore.user.roles.includes('ADMIN'))) {
		return <Route {...rest} render={props => <Component {...props} />} />;
	}

	return <Redirect to={redirectTo} push />;
}));


export { withSecuredRoute, SecuredRoute, AdminSecuredRoute };
