import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider, inject, observer } from 'mobx-react';
import { IntlProvider, addLocaleData } from 'react-intl';
import { brightAuthService } from 'bright-auth';
import I18NCountries from 'i18n-iso-countries';

import { initializeStoreClient } from './shared/language-store.js';
import { initializeUserStore } from './shared/user.store.js';
import initializeGeneralStore from './shared/general.store.js';
import localizedItems from './shared/localization-items.js';

import App from './app.js';
import GoogleAnalytics from './infrastructure/ga.js';

import './index.scss';

const availableLanguages = Object.values(localizedItems).map(item => item);

const messages = {};
availableLanguages.forEach((lang) => {
	/* eslint-disable import/no-dynamic-require */
	// eslint-disable-next-line global-require
	messages[lang.value] = require(`./assets/lang/${lang.value}.json`);
	// eslint-disable-next-line global-require
	if (lang.value !== 'en') require(`./../node_modules/moment/locale/${lang.locale}.js`);
});

const AppWrapper = inject('languageStore')(
	observer(({ languageStore }) => {
		// eslint-disable-next-line global-require
		const localeData = require(`react-intl/locale-data/${
			languageStore.language
		}.js`);
		addLocaleData([...localeData]);

		I18NCountries.registerLocale(
			// eslint-disable-next-line global-require
			require(`i18n-iso-countries/langs/${languageStore.language}.json`),
		);

		return (
			<IntlProvider
				locale={languageStore.language}
				messages={messages[languageStore.language]}
			>
				<Router>
					<GoogleAnalytics>
						<App />
					</GoogleAnalytics>
				</Router>
			</IntlProvider>
		);
	}),
);

const StoreWrapper = () => (
	<Provider
		languageStore={initializeStoreClient()}
		authService={brightAuthService}
		userStore={initializeUserStore(brightAuthService)}
		generalStore={initializeGeneralStore()}
	>
		<AppWrapper />
	</Provider>
);

function supportsIntersectionObserver() {
	return (
		'IntersectionObserver' in global
		&& 'IntersectionObserverEntry' in global
		&& 'intersectionRatio' in IntersectionObserverEntry.prototype
	);
}

function initApp() {
	// eslint-disable-next-line no-underscore-dangle
	if (window.__SSR__) {
		window.onload = () => {
			Loadable.preloadReady().then(() => {
				ReactDOM.hydrate(<StoreWrapper />, document.getElementById('root'));
			});
		};
	} else {
		ReactDOM.render(<StoreWrapper />, document.getElementById('root'));
	}
}

if (!supportsIntersectionObserver()) {
	import('intersection-observer').then(() => {
		initApp();
	});
} else {
	initApp();
}
