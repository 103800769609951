import React, { Component, Fragment } from 'react';
import {
	CustomInput,
	InputGroup,
	Tooltip,
} from 'reactstrap';
import { SingleDatePicker } from 'react-dates';
import TimePicker from 'rc-time-picker';
import cn from 'classnames';
import Select from 'react-select';
import 'react-dates/initialize.js';
import 'react-dates/lib/css/_datepicker.css';

import { TertiaryButton } from './buttons.js';
import searchIcon from '../../assets/images/search.svg';
import helpIcon from '../../assets/images/ic_question.svg';
import previousCalIcon from '../../assets/images/datepicker/previous.svg';
import nextCalIcon from '../../assets/images/datepicker/next.svg';
import calendarIcon from '../../assets/images/datepicker/calendar.svg';
import disabledCalendarIcon from '../../assets/images/datepicker/disabledCalendar.svg';
import timepickerIcon from '../../assets/images/timepicker/timepicker-icon.svg';
import timepickerDisabledIcon from '../../assets/images/timepicker/timepicker-disabled-icon.svg';

import 'rc-time-picker/assets/index.css';
import './inputs.scss';

class HelpTooltip extends Component {
	state = {
		tooltipOpen: false,
	};

	toggle = () => {
		this.setState({
			tooltipOpen: !this.state.tooltipOpen,
		});
	};

	render() {
		const { tooltipOpen } = this.state;
		const {
			helpText, name, placement = 'right-start', offset = '-18, 7',
		} = this.props;

		return (
			<Fragment>
				<img
					src={helpIcon}
					alt={name}
					id={`tooltip_${name}`}
					onClick={this.toggle}
				/>
				<Tooltip
					className={`helpTooltip ${name}`}
					placement={placement}
					isOpen={tooltipOpen}
					target={`tooltip_${name}`}
					toggle={this.toggle}
					modifiers={{
						offset: { enabled: true, offset },
					}}
				>
					<span dangerouslySetInnerHTML={{ __html: helpText }} />
				</Tooltip>
			</Fragment>
		);
	}
}

class TextBox extends Component {
	state = {
		showPassword: false,
		auxType: '',
	};

	handleKeyPress = (e) => {
		const { onEnterPressed } = this.props;

		if (e.key === 'Enter' && onEnterPressed) {
			onEnterPressed();
		}
	};

	iconPress = () => {
		const { onEnterPressed } = this.props;
		if (onEnterPressed) {
			onEnterPressed();
		}
	};

	showPasswordToggle = () => {
		this.setState(prevState => ({
			auxType: prevState.showPassword ? 'password' : 'text',
			showPassword: !prevState.showPassword,
		}));
	};

	render() {
		const {
			label,
			className,
			type,
			name,
			ariaLabel,
			placeholder,
			disabled,
			value,
			onChange,
			required,
			message,
			htmlMessage,
			success,
			error,
			maxLength,
			validationMessage,
			handleChange,
			handleFocus,
			handleBlur,
			handleKeyDown,
			materialIcon,
			imageIcon,
			onEnterPressed,
			help,
			min,
			autoFill,
		} = this.props;

		const { showPassword, auxType } = this.state;

		return (
			<div
				className={cn('text-box', {
					success: success || false,
					error: error || false,
					disabled,
					'has-icon': materialIcon || false,
					[className]: className || false,
				})}
			>
				{(label || help || maxLength) && (
					<div className="text-box-label-container">
						<div className="text-box-label">
							{label}
							{help && <img helptext={help} src={helpIcon} alt={label} />}
						</div>
						{!!maxLength && (
							<div className="text-right text-box-label-max-length">
								{`${value ? value.length : 0}/${maxLength}`}
							</div>
						)}
					</div>
				)}
				<div className="text-box-input-wrapper">
					<input
						className="text-box-input"
						type={auxType || type}
						name={name}
						disabled={disabled}
						aria-label={ariaLabel}
						placeholder={placeholder}
						value={value}
						onChange={onChange || handleChange}
						onFocus={handleFocus}
						onBlur={handleBlur}
						onKeyPress={this.handleKeyPress}
						onKeyDown={handleKeyDown}
						required={required}
						maxLength={maxLength}
						min={min}
						autoComplete={autoFill}
					/>

					{materialIcon && (
						<div className="text-box-icon-wrapper">
							<i className="material-icons text-box-icon">{materialIcon}</i>
						</div>
					)}

					{imageIcon && (
						<div className="text-box-icon-wrapper">
							{ onEnterPressed ? (
								<img
									className="text-box-icon clickable"
									alt=""
									src={imageIcon}
									onClick={this.iconPress}
								/>
							)
								: (
									<img
										className="text-box-icon"
										alt=""
										src={imageIcon}
									/>
								)
							}
						</div>
					)}

					{
						type === 'password' && value && !showPassword
						&& <i className="material-icons password-icon" onClick={this.showPasswordToggle}>visibility</i>
					}

					{
						type === 'password' && value && showPassword
						&& <i className="material-icons password-icon" onClick={this.showPasswordToggle}>visibility_off</i>
					}

					{success && (
						<i className="material-icons text-box-validation-icon">done</i>
					)}
				</div>

				{error
				&& validationMessage && (
					<div className="text-box-message">{validationMessage}</div>
				)}

				{!error && message && <div className="text-box-message">{message}</div>}

				{!error && htmlMessage
				&& <div className="text-box-message" dangerouslySetInnerHTML={{ __html: htmlMessage }} />
				}
			</div>
		);
	}
}

class SearchTextBox extends Component {
	handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			this.props.onFilterSearch();
		}
	};

	render() {
		const {
			label,
			className,
			name,
			ariaLabel,
			value,
			onChange,
			placeholder,
			onKeyDown,
		} = this.props;
		return (
			<div
				className={cn('search-box', {
					[className]: className || false,
				})}
			>
				{label && <div className="search-box-label">{label}</div>}

				<div className="search-box-input-wrapper">
					<div className="search-box-icon-wrapper">
						<img src={searchIcon} alt="" />
					</div>

					<input
						className="search-box-input"
						type="text"
						autoComplete="off"
						name={name}
						aria-label={ariaLabel}
						placeholder={placeholder}
						value={value}
						onChange={onChange}
						onKeyPress={this.handleKeyPress}
						onKeyDown={onKeyDown}
					/>
				</div>
			</div>
		);
	}
}

const TextArea = ({
	label,
	type,
	name,
	ariaLabel,
	placeholder,
	value,
	onChange,
	required,
	maxLength,
	message,
	success,
	error,
	validationMessage,
	handleChange,
	handleBlur,
	rows,
	help,
}) => (
	<div
		className={cn('text-area', {
			success: success || false,
			error: error || false,
		})}
	>
		{(label || help || maxLength) && (
			<div className="text-area-label-container">
				<div className="text-area-label">
					{label}
					{!!help && <HelpTooltip helpText={help} name={name} />}
				</div>
				{!!maxLength && (
					<div className="text-right text-area-label-max-length">
						{`${value ? value.length : 0}/${maxLength}`}
					</div>
				)}
			</div>
		)}

		<textarea
			className="text-area-input"
			type={type}
			name={name}
			aria-label={ariaLabel}
			placeholder={placeholder}
			value={value}
			onChange={onChange || handleChange}
			onBlur={handleBlur}
			required={required}
			maxLength={maxLength}
			rows={rows}
		/>

		{success && <i className="material-icons text-area-icon">done</i>}
		{error && <i className="material-icons text-area-icon">error</i>}

		{error
			&& validationMessage && (
			<div className="text-area-message">{validationMessage}</div>
		)}

		{!error && message && <div className="text-area-message">{message}</div>}
	</div>
);

const TestPrevIcon = () => (
	<img
		className="DayPickerNavigation_button__horizontalDefault DayPickerNavigation_leftButton__horizontalDefault"
		src={previousCalIcon}
		alt="previous"
	/>
);

const TestNextIcon = () => (
	<img
		className="DayPickerNavigation_button__horizontalDefault DayPickerNavigation_rightButton__horizontalDefault"
		src={nextCalIcon}
		alt="next"
	/>
);
const DatePicker = ({
	label,
	message,
	success,
	error,
	validationMessage,
	help,
	date,
	onChange,
	onFocusChange,
	focused,
	disabled,
	dateprops,
}) => (
	<div
		className={cn('datepicker', {
			success: success || false,
			error: error || false,
			changed: date,
		})}
	>
		{(label || help) && (
			<div className="datepicker-label-container">
				<div className="datepicker-label">
					{label}
					{help && <img src={helpIcon} alt={label} />}
				</div>
			</div>
		)}

		<SingleDatePicker
			navPrev={<TestPrevIcon />}
			navNext={<TestNextIcon />}
			hideKeyboardShortcutsPanel
			customInputIcon={<img src={disabled ? disabledCalendarIcon : calendarIcon} alt="date" />}
			block
			daySize={36}
			verticalSpacing={10}
			date={date}
			onDateChange={onChange}
			onFocusChange={onFocusChange}
			focused={focused}
			disabled={disabled}
			{...dateprops}
		/>

		{success && (
			<i className="material-icons datepicker-validation-icon">done</i>
		)}
		{error && (
			<i className="material-icons datepicker-validation-icon">error</i>
		)}

		{error
			&& validationMessage && (
			<div className="datepicker-message">{validationMessage}</div>
		)}

		{!error && message && <div className="datepicker-message">{message}</div>}
	</div>
);

const CustomTimePicker = ({
	label,
	error,
	placeholder,
	value,
	onOpen,
	onChange,
	onClose,
	disabled,
}) => (
	<div
		className={cn('timepicker', {
			error: error || false,
		})}
	>
		{label && (
			<div className="timepicker-label-container">
				<div className="timepicker-label">{label}</div>
			</div>
		)}

		<TimePicker
			className="timepicker-control-wrapper"
			placeholder={placeholder}
			inputIcon={<div className="timepicker-icon"><img src={disabled ? timepickerDisabledIcon : timepickerIcon} alt="" /></div>}
			clearIcon={<div className="timepicker-clear"><i className="material-icons">clear</i></div>}
			showSecond={false}
			value={value}
			onChange={onChange}
			onClose={onClose}
			onOpen={onOpen}
			disabled={disabled}
		/>
	</div>
);

const CheckBox = ({
	className, label, name, ariaLabel, value, onChange, disabled,
}) => (
	<div
		className={cn('check-box', {
			checked: value || false,
			disabled,
			[className]: className || false,
		})}
	>
		<div className="check-box-input-container">
			<input
				className="check-box-input"
				type="checkbox"
				id={name ? `checkbox-${name}` : null}
				name={name}
				aria-label={ariaLabel}
				checked={value}
				onChange={onChange}
				disabled={disabled}
			/>

			<i className="material-icons check-box-checkmark">done</i>
		</div>

		{label && (
			<div
				className="check-box-label"
				onClick={() => {
					if (!disabled) {
						onChange();
					}
				}}
			>
				<label htmlFor={name ? `checkbox-${name}` : null}>{label}</label>
			</div>
		)}
	</div>
);

const RadioGroup = ({
	className, name, options, onChange, value,
}) => (
	<div
		className={cn('radio-group', {
			[className]: className || false,
		})}
	>
		{
			options.map(option => (
				<CustomInput
					key={option.value}
					type="radio"
					id={`radio-element-${option.value}`}
					name={name}
					onChange={() => onChange(option)}
					label={option.label}
					checked={value === option.value}
				/>
			))
		}
	</div>
);

const CheckBoxHTML = ({
	className, label, name, ariaLabel, value, onChange, disabled,
}) => (
	<div
		className={cn('check-box', {
			checked: value || false,
			[className]: className || false,
			disabled,
		})}
	>
		<div className="check-box-input-container">
			<input
				className="check-box-input"
				type="checkbox"
				id={`checkbox-${name}`}
				name={name}
				aria-label={ariaLabel}
				checked={value}
				onChange={onChange}
				disabled={disabled}
			/>

			<i className="material-icons check-box-checkmark">done</i>
		</div>

		{label && (
			<div className="check-box-label">
				<label htmlFor={`checkbox-${name}`} dangerouslySetInnerHTML={{ __html: label }} />
			</div>
		)}
	</div>
);

const customFilterOption = (option, rawInput) => {
	const words = rawInput.split(' ');
	return words.reduce(
		(acc, cur) => acc && option.label.toLowerCase().startsWith(cur.toLowerCase()),
		true,
	);
};

const DropdownList = ({
	label,
	name,
	className,
	ariaLabel,
	placeholder,
	value,
	searchable,
	clearable,
	multi,
	onChange,
	onBlur,
	options,
	loadOptions,
	message,
	success,
	error,
	validationMessage,
	defaultValue,
	autocomplete,
	disabled,
	autoFill,
	key,
	filterOption = customFilterOption,
}) => (
	<div
		className={cn('dropdown-list', {
			success: success || false,
			error: error || false,
		})}
	>
		{label && <div className="dropdown-list-label">{label}</div>}

		{autocomplete
			? (
				<Select.Async
					key={key}
					name={name}
					className={className}
					aria-label={ariaLabel}
					placeholder={placeholder}
					value={value}
					searchable={searchable}
					clearable={clearable}
					multi={multi}
					defaultValue={defaultValue}
					onChange={onChange}
					onBlur={onBlur}
					filterOption={filterOption}
					loadOptions={loadOptions}
					arrowRenderer={({ isOpen }) => (isOpen ? (
						<i className="material-icons dropdown-list-icon">keyboard_arrow_up</i>
					) : (
						<i className="material-icons dropdown-list-icon">keyboard_arrow_down</i>
					))
					}
					disabled={disabled}
					inputProps={{
						autoComplete: autoFill,
					}}
				/>
			)

			: (
				<Select
					name={name}
					className={className}
					aria-label={ariaLabel}
					placeholder={placeholder}
					value={value}
					searchable={searchable}
					clearable={clearable}
					multi={multi}
					defaultValue={defaultValue}
					onChange={onChange}
					filterOption={customFilterOption}
					onBlur={onBlur}
					options={options}
					arrowRenderer={({ isOpen }) => (isOpen ? (
						<i className="material-icons dropdown-list-icon">keyboard_arrow_up</i>
					) : (
						<i className="material-icons dropdown-list-icon">keyboard_arrow_down</i>
					))
					}
					disabled={disabled}
					inputProps={{
						autoComplete: autoFill,
					}}
				/>
			)
		}

		{message && <div className="dropdown-list-message">{message}</div>}
		{error && validationMessage && (
			<div className="dropdown-list-message">{validationMessage}</div>
		)}
	</div>
);

const TextBoxButton = ({
	label,
	type,
	name,
	ariaLabel,
	placeholder,
	value,
	onChange,
	required,
	message,
	success,
	error,
	validationMessage,
	handleChange,
	handleBlur,
	onClick,
	disabled,
	help,
	maxLength,
	btnIcon,
	className,
	children,
}) => (
	<InputGroup className={cn('input-button', { 'btn-icon': !!btnIcon, [className]: className || false })}>
		<TextBox
			label={label}
			type={type}
			name={name}
			ariaLabel={ariaLabel}
			placeholder={placeholder}
			value={value}
			onChange={onChange || handleChange}
			required={required}
			message={message}
			success={success}
			error={error}
			validationMessage={validationMessage}
			handleBlur={handleBlur}
			help={help}
			maxLength={maxLength}
			onEnterPressed={!disabled && onClick}
		/>
		<div className="button-wrapper">
			<TertiaryButton type="button" onClick={onClick} disabled={disabled}>
				{btnIcon && <img src={btnIcon} alt="upload" />}
				{error && (
					<i className="material-icons text-box-validation-icon">error</i>
				)}
				{children}
			</TertiaryButton>
		</div>
	</InputGroup>
);

class TextBoxDropdown extends Component {
	state = {
		focused: false,
	};

	handleFocus = () => {
		this.setState({
			focused: true,
		});
	};

	handleBlur = () => {
		const {
			form, field,
		} = this.props;

		form.setFieldTouched(field.name, true, true);

		this.setState({
			focused: false,
		});
	};

	render() {
		const {
			label,
			type,
			name,
			ariaLabel,
			placeholder,
			value,
			options,
			handleAmountChange,
			handleKeyDown,
			handleUnitChange,
			error,
			selectElementWidth,
			min,
		} = this.props;

		const { focused } = this.state;

		return (
			<div className={cn('text-box-dropdown', { focused, error })}>

				{label && <div className="text-box-label">{label}</div>}

				<div className="text-box-dropdown-wrapper">
					<TextBox
						type={type}
						name={name}
						aria-label={ariaLabel}
						placeholder={placeholder}
						value={value.amount}
						handleFocus={this.handleFocus}
						handleBlur={this.handleBlur}
						onChange={handleAmountChange}
						handleKeyDown={handleKeyDown}
						error={error}
						min={min}
					/>

					<Select
						style={{ width: selectElementWidth }}
						name={name}
						aria-label={ariaLabel}
						value={value.unit}
						options={options}
						searchable={false}
						clearable={false}
						onChange={handleUnitChange}
						error={error}
					/>
				</div>
			</div>
		);
	}
}


const LanguageDropdown = ({
	label,
	name,
	ariaLabel,
	placeholder,
	value,
	searchable,
	clearable,
	onChange,
	onBlur,
	options,
	success,
	error,
}) => (
	<div
		className={cn('language-dropdown-list', {
			success: success || false,
			error: error || false,
		})}
	>
		{label && <div className="language-list-label">{label}</div>}

		<Select
			name={name}
			aria-label={ariaLabel}
			placeholder={placeholder}
			value={value}
			searchable={searchable}
			clearable={clearable}
			onChange={onChange}
			onBlur={onBlur}
			options={options}
			arrowRenderer={() => <Fragment />}
		/>
	</div>
);

export {
	TextBox,
	TextArea,
	TextBoxButton,
	TextBoxDropdown,
	CheckBox,
	RadioGroup,
	CheckBoxHTML,
	DropdownList,
	LanguageDropdown,
	DatePicker,
	CustomTimePicker,
	SearchTextBox,
	HelpTooltip,
};
