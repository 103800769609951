import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const UserAccountPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "user-account" */ './user-account.page.js'),
	modules: ['./user-account.page.js'],
	webpack: () => [require.resolveWeak('./user-account.page.js')],
	loading: Loading,
});

export default UserAccountPageAsync;
