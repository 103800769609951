import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const SubmitChallengeSolutionPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "submit-challenge-solution" */'./submit-challenge-solution.page.js'),
	modules: ['./submit-challenge-solution.page.js'],
	webpack: () => [require.resolveWeak('./submit-challenge-solution.page.js')],
	loading: Loading,
});

export default SubmitChallengeSolutionPageAsync;
