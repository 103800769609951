import React, { Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import {
	ButtonDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Button,
	Collapse,
} from 'reactstrap';

import ImageAvatar from './ui-toolkit/image-avatar.js';
import Utils from './shared/util.js';

import { ReactComponent as ProfileIcon } from '../assets/images/user-widget/profile.svg';
import { ReactComponent as SwitchProfileIcon } from '../assets/images/user-widget/switch_user.svg';
import { ReactComponent as BackofficeIcon } from '../assets/images/user-widget/backoffice.svg';
import { ReactComponent as AccountIcon } from '../assets/images/user-widget/account.svg';
import { ReactComponent as PaymentsIcon } from '../assets/images/user-widget/payments.svg';
import { ReactComponent as RewardsIcon } from '../assets/images/user-widget/rewards.svg';
import { ReactComponent as LogoutIcon } from '../assets/images/user-widget/logout.svg';
import { ReactComponent as BackIcon } from '../assets/images/user-widget/back.svg';

import './user-widget.scss';


const HeaderProfileAvatar = inject('userStore')(
	observer(({
		userStore,
	}) => {
		const { profile, user } = userStore;
		const { type } = profile;

		return (
			Object.keys(profile).length
				? (
					<Fragment>
						<ImageAvatar
							initials={Utils.getNameInitials(profile.name)}
							picture={profile.avatar}
							alt={profile.name}
							height={32}
							width={32}
						/>
						<div className="profile-wrapper">
							<span className="profileName">
								{profile.name}
							</span>
							<span className="selectedProfile">
								<FormattedMessage id={`navbar.${type}`} />
							</span>
						</div>
					</Fragment>)
				: (
					<Fragment>
						<ImageAvatar
							picture={user.avatar}
							initials={user.initials}
							alt={user.fullName}
							height={32}
							width={32}
						/>
						<span className="userName">
							{user.firstName}
						</span>
					</Fragment>
				));
	}),
);


const SwitchUserButton = ({ toggleSwitchUserMenu }) => (
	<div className="switch-user-wrapper" onClick={toggleSwitchUserMenu}>
		<div className="option-icon">
			<BackIcon />
		</div>
		<div className="option-icon">
			<SwitchProfileIcon className="switch-user" />
		</div>
		<FormattedMessage id="navbar.switch_profile" />
	</div>
);

const MenuProfileHeader = ({ switchUserMenu, toggleSwitchUserMenu }) => (
	<DropdownItem header>
		{
			switchUserMenu
				? <SwitchUserButton toggleSwitchUserMenu={toggleSwitchUserMenu} />
				:	(
					<HeaderProfileAvatar />
				)
		}
	</DropdownItem>
);

const UserButtonContent =	inject('userStore')(
	observer(({
		userStore,
	}) => {
		const { profile, user } = userStore;
		const { type } = profile;

		return (
			Object.keys(profile).length
				? (

					<Fragment>
						<div className="double-avatar-wrapper">
							<ImageAvatar
								initials={Utils.getNameInitials(user.fullName)}
								picture={user.avatar}
								alt={user.fullName}
								width={48}
								height={48}
							/>

							<ImageAvatar
								initials={Utils.getNameInitials(profile.name)}
								className="double-avatar"
								picture={profile.avatar}
								alt={profile.name}
								width={29}
								height={29}
							/>
						</div>


						<div className="user-wrapper">
							<div className="fullName">
								{user.fullName}
							</div>
							<div className="profile">
								<span className="profileName">
									{profile.name}
								</span>
								<span className="selectedProfile">
									<FormattedMessage id={`navbar.${type}`} />
								</span>
							</div>
						</div>
					</Fragment>
				)
				: (
					<Fragment>
						<ImageAvatar
							picture={user.avatar}
							initials={user.initials}
							alt={user.fullName}
							width={48}
							height={48}
						/>
						<div className="userName">
							{user.fullName}
						</div>
					</Fragment>
				));
	}),
);


const CollapseNavigationMenuOptions = withRouter(inject('authService', 'generalStore')(({
	collapseOpen, toggleSwitchUserMenu, disableSwitchUser, authService, userStore, generalStore, location, history,
}) => (
	<Collapse isOpen={collapseOpen && generalStore.navbarOpen}>
		<div className="collapse-wrapper d-lg-none">
			<div
				className="collapse-option"
				onClick={() => {
					history.push('/profile');
					generalStore.closeNavbar();
				}}
			>
				<div className="option-icon">
					<ProfileIcon />
				</div>
				<FormattedMessage id="navbar.profile" />
			</div>

			<div
				className={cn('collapse-option', { disabled: disableSwitchUser })}
				onClick={() => { if (!disableSwitchUser) { toggleSwitchUserMenu(); } }}
			>
				<div className="option-icon">
					<SwitchProfileIcon />
				</div>
				<FormattedMessage id="navbar.switch_profile" />
			</div>


			{
				userStore.user && userStore.user.roles && (userStore.user.roles.includes('SUPER_ADMIN') || userStore.user.roles.includes('ADMIN')) && (
					<div
						className="collapse-option"
						onClick={() => {
							history.push('/admin');
							generalStore.closeNavbar();
						}}
					>
						<div className="option-icon">
							<BackofficeIcon />
						</div>
						<FormattedMessage id="navbar.backoffice" />
					</div>
				)
			}


			<div
				className="collapse-option"
				onClick={() => {
					history.push('/account');
					generalStore.closeNavbar();
				}}
			>
				<div className="option-icon">
					<AccountIcon />
				</div>
				<FormattedMessage id="navbar.account" />
			</div>

			{
				userStore.profile.type === 'SEEKER' && (
					<div
						className="collapse-option"
						onClick={() => {
							history.push('/payments');
							generalStore.closeNavbar();
						}}
					>
						<div className="option-icon">
							<PaymentsIcon />
						</div>
						<FormattedMessage id="navbar.payments" />
					</div>
				)
			}

			{ userStore.profile.type === 'SOLVER' && (
				<div
					className="collapse-option"
					onClick={() => {
						history.push('/rewards');
						generalStore.closeNavbar();
					}}
				>
					<div className="option-icon">
						<RewardsIcon />
					</div>
					<FormattedMessage id="navbar.rewards" />
				</div>
			)}

			<div
				className="collapse-option"
				onClick={() => {
					userStore.storeUser({
						userLoggedOut: true,
						logoutLocationKey: location.key,
					});
					userStore.clearProfiles();

					authService.logout();

					Utils.resetDriftChat();
				}}
			>
				<div className="option-icon">
					<LogoutIcon />
				</div>
				<FormattedMessage id="navbar.logout" />
			</div>

		</div>
	</Collapse>
)));


const CollapseMenuProfileOptions = inject('authService', 'generalStore')(({
	collapseOpen, switchUserMenu, toggleSwitchUserMenu, seekers, solvers, userStore, generalStore,
}) => (
	<Collapse isOpen={collapseOpen && generalStore.navbarOpen}>
		<div className="collapse-switch-user-wrapper d-lg-none">
			<SwitchUserButton toggleSwitchUserMenu={toggleSwitchUserMenu} />
		</div>
		<div className={cn('collapse-wrapper d-lg-none', { 'switch-user-option': switchUserMenu })}>
			{
				!!seekers.length && (
					<Fragment>
						<div className="switch-user-title">
							<FormattedMessage id="switch_user.seeker" />
						</div>
						<div className="switch-user-divider" />
					</Fragment>)
			}
			{
				seekers.map(seeker => (
					<div
						key={seeker.id}
						className="collapse-option"
						onClick={() => {
							userStore.switchProfile(true, seeker);
							toggleSwitchUserMenu();
							generalStore.closeNavbar();
						}}
					>
						<div className="option-wrapper-switch-user">
							<div className="option-icon">
								<ImageAvatar
									initials={Utils.getNameInitials(seeker.name)}
									picture={seeker.avatar}
									alt={seeker.name}
									height={26}
									width={26}
								/>
							</div>
							<span>{seeker.name}</span>
						</div>
					</div>
				))
			}
			{ !!solvers.length && (
				<Fragment>
					<div className="switch-user-title">
						<FormattedMessage id="switch_user.solver" />
					</div>
					<div className="switch-user-divider" />
				</Fragment>)
			}
			{
				solvers.map(solver => (
					<div
						key={solver.id}
						className="collapse-option"
						onClick={() => {
							userStore.switchProfile(true, solver);
							toggleSwitchUserMenu();
							generalStore.closeNavbar();
						}}
					>
						<div className="option-wrapper-switch-user">
							<div className="option-icon">
								<ImageAvatar
									initials={Utils.getNameInitials(solver.name)}
									picture={solver.avatar}
									alt={solver.name}
									height={26}
									width={26}
								/>
							</div>
							<span>{solver.name}</span>
						</div>
					</div>
				))
			}
		</div>
	</Collapse>
));


const MenuNavigationOptions = withRouter(inject('authService')(({
	toggleSwitchUserMenu, disableSwitchUser, authService, userStore, location, history,
}) => (
	<div className="navigation-list-wrapper">
		<DropdownItem
			className="user-dropdown-menu-option"
			onClick={() => history.push('/profile')}
			disabled={disableSwitchUser}
		>
			<div className="option-wrapper">
				<div className="option-icon">
					<ProfileIcon />
				</div>
				<FormattedMessage id="navbar.profile" />
			</div>
		</DropdownItem>

		<DropdownItem
			className="user-dropdown-menu-option"
			toggle={false}
			disabled={disableSwitchUser}
			onClick={toggleSwitchUserMenu}
		>
			<div className="option-wrapper">
				<div className="option-icon">
					<SwitchProfileIcon />
				</div>
				<FormattedMessage id="navbar.switch_profile" />
			</div>
		</DropdownItem>

		{
			userStore.user && userStore.user.roles && (userStore.user.roles.includes('SUPER_ADMIN') || userStore.user.roles.includes('ADMIN')) && (
				<DropdownItem
					className="user-dropdown-menu-option"
					onClick={() => history.push('/admin')}
				>
					<div className="option-wrapper">
						<div className="option-icon">
							<BackofficeIcon />
						</div>
						<FormattedMessage id="navbar.backoffice" />
					</div>
				</DropdownItem>
			)
		}

		<DropdownItem
			className="user-dropdown-menu-option"
			onClick={() => history.push('/account')}
		>
			<div className="option-wrapper">
				<div className="option-icon">
					<AccountIcon />
				</div>
				<FormattedMessage id="navbar.account" />
			</div>
		</DropdownItem>

		 { userStore.profile.type === 'SEEKER' && (
			<DropdownItem
				className="user-dropdown-menu-option"
				onClick={() => history.push('/payments')}
			>
				<div className="option-wrapper">
					<div className="option-icon">
						<PaymentsIcon />
					</div>
					<FormattedMessage id="navbar.payments" />
				</div>
			</DropdownItem>
		)}

		{ userStore.profile.type === 'SOLVER' && (
			<DropdownItem
				className="user-dropdown-menu-option"
				onClick={() => history.push('/rewards')}
			>
				<div className="option-wrapper">
					<div className="option-icon">
						<RewardsIcon />
					</div>
					<FormattedMessage id="navbar.rewards" />
				</div>
			</DropdownItem>
		)}

		<DropdownItem
			className="user-dropdown-menu-option"
			onClick={() => {
				userStore.storeUser({
					userLoggedOut: true,
					logoutLocationKey: location.key,
				});
				userStore.clearProfiles();

				authService.logout();

				Utils.resetDriftChat();
			}}
		>
			<div className="option-wrapper">
				<div className="option-icon">
					<LogoutIcon />
				</div>
				<FormattedMessage id="navbar.logout" />
			</div>
		</DropdownItem>

	</div>
)));


const MenuProfileOptions = ({
	 userStore, toggleSwitchUserMenu, seekers, solvers,
}) => (
	<div className="switch-user-list-wrapper">
		{
			!!seekers.length && (
				<Fragment>
					<DropdownItem className="user-dropdown-divider-title" disabled>
						<FormattedMessage id="switch_user.seeker" />
					</DropdownItem>
					<DropdownItem divider />
				</Fragment>
			)}
		{
			seekers.map(seeker => (
				<DropdownItem
					key={seeker.id}
					className="user-dropdown-menu-option"
					onClick={() => {
						userStore.switchProfile(true, seeker);
						toggleSwitchUserMenu();
					}}
				>
					<div className="option-wrapper option-wrapper-switch-user">
						<div className="option-icon">
							<ImageAvatar
								initials={Utils.getNameInitials(seeker.name)}
								picture={seeker.avatar}
								alt={seeker.name}
								height={26}
								width={26}
							/>
						</div>
						<span className="seeker">
							{seeker.name}
						</span>
					</div>
				</DropdownItem>
			))
		}
		{
			!!solvers.length && (
				<Fragment>
					<DropdownItem className="user-dropdown-divider-title" disabled>
						<FormattedMessage id="switch_user.solver" />
					</DropdownItem>
					<DropdownItem divider />
				</Fragment>)
		}
		{
			solvers.map(solver => (
				<DropdownItem
					key={solver.id}
					className="user-dropdown-menu-option"
					onClick={() => {
						userStore.switchProfile(true, solver);
						toggleSwitchUserMenu();
					}}
				>
					<div className="option-wrapper option-wrapper-switch-user">
						<div className="option-icon">
							<ImageAvatar
								initials={Utils.getNameInitials(solver.name)}
								picture={solver.avatar}
								alt={solver.name}
								height={26}
								width={26}
							/>
						</div>
						<span className="solver">
							{solver.name}
						</span>
					</div>
				</DropdownItem>
			))
		}
	</div>
);


class UserWidget extends React.Component {
	state = {
		dropdownOpen: false,
		collapseOpen: false,
	};

	toggleDropdown = () => {
		this.setState(prevState => ({
			dropdownOpen: !prevState.dropdownOpen,
		}),
		() => {
			if (!this.state.dropdownOpen) {
				this.props.closeSwitchUserMenu();
			}
		});
	};

	toggleCollapse = () => {
		this.setState(prevState => ({
			collapseOpen: !prevState.collapseOpen,
		}),
		() => {
			if (!this.state.collapseOpen) {
				this.props.closeSwitchUserMenu();
			}
		});
	};


	render() {
		const {
			userStore, generalStore,
			switchUserMenu, toggleSwitchUserMenu,
		} = this.props;

		const { seekers, solvers } = userStore;

		const { navbarOpen } = generalStore;


		const {
			collapseOpen, dropdownOpen,
		} = this.state;

		if (userStore.isFetching) return null;

		return (
			<div className="user-widget">
				<div
					className={cn('user-widget-mobile hide-profile', {
						'show-profile': navbarOpen,
					})}
				>
					<Button
						className={cn('user-button dropdown-toggle d-flex d-lg-none', {
							expanded: collapseOpen,
						})}
						color="link"
						onClick={this.toggleCollapse}
					>
						<UserButtonContent />
					</Button>
					{
						switchUserMenu
							? (
								<CollapseMenuProfileOptions
									switchUserMenu={switchUserMenu}
									collapseOpen={collapseOpen}
									toggleSwitchUserMenu={toggleSwitchUserMenu}
									userStore={userStore}
									seekers={seekers}
									solvers={solvers}
								/>)
							: (
								<CollapseNavigationMenuOptions
									collapseOpen={collapseOpen}
									toggleSwitchUserMenu={toggleSwitchUserMenu}
									disableSwitchUser={solvers.length === 0 && seekers.length === 0}
									userStore={userStore}
								/>
							)
					}
				</div>

				<ButtonDropdown
					className="user-dropdown"
					isOpen={dropdownOpen}
					toggle={this.toggleDropdown}
				>
					<DropdownToggle
						className="user-button d-none d-lg-flex"
						color="link"
						caret
					>
						<UserButtonContent />
					</DropdownToggle>

					<DropdownMenu
						className="user-dropdown-menu"
						right
						modifiers={{
							offset: {
								enabled: true,
								offset: '-15%, 30',
							},
						}}
					>
						<MenuProfileHeader
							switchUserMenu={switchUserMenu}
							toggleSwitchUserMenu={toggleSwitchUserMenu}
						/>
						{
							switchUserMenu
								? (
									<MenuProfileOptions
										userStore={userStore}
										seekers={seekers}
										solvers={solvers}
										toggleSwitchUserMenu={toggleSwitchUserMenu}
									/>
								)
								:	(
									<MenuNavigationOptions
										toggleSwitchUserMenu={toggleSwitchUserMenu}
										disableSwitchUser={solvers.length === 0 && seekers.length === 0}
										userStore={userStore}
									/>
								)
						}
					</DropdownMenu>
				</ButtonDropdown>
			</div>
		);
	}
}

export default inject('userStore', 'generalStore')(
	observer(UserWidget),
);
