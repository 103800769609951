class User {
	avatar = '';

	challengeAreas = [];

	country = '';

	email = '';

	firstName = '';

	id = '';

	isActive = false;

	termsAcceptance = false;

	langCode = '';

	lastName = '';

	password = '';

	phoneNumber = '';

	roles = [];

	initials = '';

	fullName = '';

	seekers = [];

	solutionStats = {};

	solvers = [];

	newsletterSubscription = false;

	constructor({
		avatar,
		challengeAreas,
		country,
		email,
		firstName,
		id,
		isActive,
		termsAcceptance,
		langCode,
		lastName,
		password,
		phoneNumber,
		roles,
		seekers = [],
		solutionStats,
		solvers = [],
		newsletterSubscription,
	}) {
		this.avatar = avatar;
		this.challengeAreas = challengeAreas;
		this.country = country;
		this.email = email;
		this.firstName = firstName;
		this.id = id;
		this.isActive = isActive;
		this.termsAcceptance = termsAcceptance;
		this.langCode = langCode;
		this.lastName = lastName;
		this.password = password;
		this.phoneNumber = phoneNumber;
		this.roles = roles;
		this.seekers = seekers;
		this.solutionStats = solutionStats;
		this.solvers = solvers;
		this.newsletterSubscription = newsletterSubscription;

		this.initials = this.getUserInitials();
		this.fullName = `${firstName} ${lastName}`;
	}

	getUserInitials = () => {
		let initials = '';

		if (this.firstName) {
			initials += this.firstName.charAt(0);
		}

		if (this.lastName) {
			initials += this.lastName.charAt(0);
		}

		return initials;
	};
}

export default User;
