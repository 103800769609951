import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const SolutionsPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "solutions" */'./solutions.page.js'),
	modules: ['./solutions.page.js'],
	webpack: () => [require.resolveWeak('./solutions.page.js')],
	loading: Loading,
});

export default SolutionsPageAsync;
