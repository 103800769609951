import React from 'react';
import { FormattedMessage } from 'react-intl';

import underMaintenance from '../../assets/images/under-maintenance.svg';

import './under-maintenance.scss';

class ErrorBoundary extends React.Component {
	state = { hasError: false };

	componentDidCatch(error, info) {
		this.setState({ hasError: true });

		// eslint-disable-next-line no-console
		console.error(error, info);
	}

	render() {
		const { hasError } = this.state;
		const { children } = this.props;

		if (hasError) {
			return (
				<div className="page-wrapper">
					<div className="under-maintenance-wrapper">
						<img src={underMaintenance} alt="Under maintenance" />
						<div className="center">
							<FormattedMessage id="under-maintenance.under-maintenance-message" />
						</div>
					</div>
				</div>
			);
		}

		return children;
	}
}

export default ErrorBoundary;
