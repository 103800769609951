import { action, decorate, observable } from 'mobx';

class GeneralStore {
	generalData = {};

	addresses = [];

	phones = [];

	navbarOpen = false;

	isLoading = false;

	animate = false;

	hasUnreadMessages = false;

	hideDriftChat = false;

	constructor(
		generalData = {},
		addresses = [],
		phones = [],
		navbarOpen = false,
		isLoading = false,
		animate = false,
		hasUnreadMessages = false,
		hideDriftChat = false,
	) {
		this.generalData = generalData;
		this.addresses = addresses;
		this.phones = phones;
		this.navbarOpen = navbarOpen;
		this.isLoading = isLoading;
		this.animate = animate;
		this.hasUnreadMessages = hasUnreadMessages;
		this.hideDriftChat = hideDriftChat;
	}

	storeGeneralData = (generalData) => {
		this.generalData = generalData;
	};

	storeAddresses = (addresses) => {
		this.addresses = addresses;
	};

	storePhones = (phones) => {
		this.phones = phones;
	};

	toggleNavbar = () => {
		this.navbarOpen = !this.navbarOpen;
	};

	closeNavbar = () => {
		this.navbarOpen = false;
	};

	startLoading = () => {
		this.isLoading = true;
	};

	stopLoading = () => {
		this.isLoading = false;
	};

	startAnimation = () => {
		this.animate = true;
	};

	stopAnimation = () => {
		this.animate = false;
	};

	storeHasUnreadMessages = (hasUnreadMessages) => {
		this.hasUnreadMessages = hasUnreadMessages;
	};

	storeHideDriftChat = (hideDriftChat) => {
		this.hideDriftChat = hideDriftChat;
	};
}

decorate(GeneralStore, {
	generalData: observable,
	addresses: observable,
	phones: observable,
	navbarOpen: observable,
	isLoading: observable,
	hasUnreadMessages: observable,
	hideDriftChat: observable,

	storeGeneralData: action,
	storeAddresses: action,
	storePhones: action,
	closeNavbar: action,
	startLoading: action,
	stopLoading: action,
	startAnimation: action,
	stopAnimation: action,
	storeHasUnreadMessages: action,
	storeHideDriftChat: action,
});

function initializeGeneralStore() {
	return new GeneralStore();
}

export default initializeGeneralStore;
