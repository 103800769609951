import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const GetLicensesPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "get-licenses" */'./get-licenses.page.js'),
	modules: ['./get-licenses.page.js'],
	webpack: () => [require.resolveWeak('./get-licenses.page.js')],
	loading: Loading,
});

export default GetLicensesPageAsync;
