import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const ChangePasswordModalAsync = Loadable({
	loader: () => import(/* webpackChunkName: "change-password-modal" */ './change-password.modal.js'),
	modules: ['./change-password.modal.js'],
	webpack: () => [require.resolveWeak('./change-password.modal.js')],
	loading: Loading,
});

export default ChangePasswordModalAsync;
