import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const RewardsPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "rewards" */ './rewards.page.js'),
	modules: ['./rewards.js'],
	webpack: () => [require.resolveWeak('./rewards.page.js')],
	loading: Loading,
});

export default RewardsPageAsync;
