import { action, decorate, observable } from 'mobx';
import UserService from './user.service.js';

class UserStore {
	user = {};

	isFetching = false;

	profile = {};

	seekers = [];

	solvers = [];

	isFetchingProfile = false;

	isSwitchingProfile = false;

	targetProfile = {};

	constructor(user = {}, profile = {}, seekers = [], solvers = []) {
		this.user = user;
		this.profile = profile;
		this.seekers = seekers;
		this.solvers = solvers;
	}

	storeUser = (user) => {
		this.user = user;
		this.isFetching = false;
	};

	storeSeekers = (seekers) => {
		this.seekers = seekers;
		this.isFetchingProfile = false;
	};

	storeSolvers = (solvers) => {
		this.solvers = solvers;
		this.isFetchingProfile = false;
	};

	storeProfile = (profile) => {
		this.profile = profile;

		if (profile.id) {
			localStorage.setItem('_idProfile', profile.id);
		}
	};

	switchProfile = (state, profile = {}) => {
		if (this.profile.id !== profile.id) {
			this.isSwitchingProfile = state;
			this.targetProfile = profile;
		}
	};

	selectProfile = (profile) => {
		UserService.selectProfile(profile.id, profile.type)
			.then(() => {
				this.storeProfile(profile);
			});
	};

	fetchUser = () => {
		UserService.getAccount()
			.then((user) => {
				this.user = user;
				if (user.roles.includes('SEEKER') || user.roles.includes('SOLVER')) {
					UserService.getActiveProfile()
						.then((profile) => {
							this.storeProfile(profile);
							this.isFetching = false;
						});
				} else {
					this.isFetching = false;
				}
			});
	};

	clearProfiles = () => {
		this.profile = {};
		localStorage.removeItem('_idProfile');
		this.solvers = [];
		this.seekers = [];
	};
}

decorate(UserStore, {
	user: observable,
	isFetching: observable,
	solvers: observable,
	seekers: observable,
	profile: observable,
	isFetchingProfile: observable,
	isSwitchingProfile: observable,
	targetProfile: observable,

	storeUser: action,
	storeSeekers: action,
	storeSolvers: action,
	selectProfile: action,
	fetchUser: action,
	clearProfiles: action,
	clearProfileSelect: action,
	switchProfile: action,
});

function initializeUserStore(brightAuthService) {
	const userStore = new UserStore();

	if (brightAuthService.isLoggedIn) {
		userStore.isFetching = true;
		userStore.fetchUser();
	}

	return userStore;
}

export { initializeUserStore };
