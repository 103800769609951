const localizationItems = [
	{
		value: 'en',
		label: 'English',
		locale: 'en-gb',
		region: 'en_GB',
		ISOCode: 'en-GB',
	},
	{
		value: 'pt',
		label: 'Português',
		locale: 'pt',
		region: 'pt_PT',
		ISOCode: 'pt-Pt',
	},
];

export default localizationItems;
