import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const LoginPageAsync = Loadable({
	loader: () => import(/* webpackChunkName: "login" */ './login.page.js'),
	modules: ['./login.page.js'],
	webpack: () => [require.resolveWeak('./login.page.js')],
	loading: Loading,
});

export default LoginPageAsync;
