import axios from 'axios';

export default class ProfileInterceptor {
	constructor() {
		this._intercept();
	}

	_intercept() {
		// Add the group id to the request
		axios.interceptors.request.use((config) => {
			const groupId = localStorage.getItem('_idProfile');

			if (groupId && !config.url.includes(process.env.REACT_APP_CMS_URL)) {
				config.headers['X-Group'] = groupId;
			}

			return config;
		}, error => Promise.reject(error));
	}
}
