import { action, decorate, observable } from 'mobx';
import moment from 'moment';
import localizedItems from './localization-items.js';

function setCookie(name, value, days) {
	let expires = '';
	if (days) {
		const date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		expires = `; expires=${date.toUTCString()}`;
	}
	window.document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

function getCookie(name) {
	const nameEQ = `${name}=`;
	const ca = window.document.cookie.split(';');
	for (let i = 0; i < ca.length; i += 1) {
		let c = ca[i];
		while (c.charAt(0) === ' ') c = c.substring(1, c.length);
		if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
	}
	return undefined;
}


class LanguageStore {
	language = 'en';

	label = 'English';

	region = 'en_GB';

	ISOCode = 'en-GB';

	constructor(language = 'en', label = 'English', region = 'en_GB', ISOCode = 'en-GB') {
		this.language = language;
		this.label = label;
		this.region = region;
		this.ISOCode = ISOCode;
	}

	changeLanguage = (newLanguage) => {
		setCookie('_language', newLanguage.value, 90);
		this.language = newLanguage.value;
		this.label = newLanguage.label;
		this.region = newLanguage.region;
		this.ISOCode = newLanguage.ISOCode;
		moment.locale(newLanguage.value);
	}
}
decorate(LanguageStore, {
	language: observable,
	label: observable,
	region: observable,
	ISOCode: observable,

	changeLanguage: action,
});

function initializeStoreClient() {
	const lang = getCookie('_language');
	moment.locale(lang || 'en');
	const findLang = localizedItems.find(l => l.value === lang);
	return new LanguageStore(lang, findLang && findLang.label, findLang && findLang.region, findLang && findLang.ISOCode);
}

function initializeStoreServer(req) {
	// eslint-disable-next-line
	const findLang = localizedItems.find(l => l.value === req.cookies._language);
	// eslint-disable-next-line
	return new LanguageStore(req.cookies._language, findLang && findLang.label, findLang && findLang.region, findLang && findLang.ISOCode);
}


export { initializeStoreClient, initializeStoreServer };
