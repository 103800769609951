import axios from 'axios';
import User from './user.model.js';

function logError(error) {
	throw error;
}

class UserService {
	getAccount() {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/api/account/`)
			.then(res => new User(res.data))
			.catch(logError);
	}

	getAccountSeekers({ page = 0, size = 50 } = {}) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/api/account/seeker`, { params: { page, size } })
			.then(res => (
				{
					contentSeekers: res.data.content,
				}))
			.catch(logError);
	}

	getAccountSolvers({ page = 0, size = 50 } = {}) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/api/account/solver`, { params: { page, size } })
			.then(res => (
				{
					contentSolvers: res.data.content,
				}))
			.catch(logError);
	}

	getActiveProfile(members = false) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/api/account/active_group`,
				{ params: { members } })
			.then(res => res.data)
			.catch(logError);
	}

	selectProfile(groupId, groupType) {
		return axios
			.put(`${process.env.REACT_APP_API_URL}/api/account/active_group`,
				`groupId=${groupId}&groupType=${groupType}`)
			.then(res => res)
			.catch(logError);
	}

	getAccountPreference(preference) {
		return axios
			.get(`${process.env.REACT_APP_API_URL}/api/account/preference?key=${preference}`)
			.then(res => res.data)
			.catch(logError);
	}

	updateAccountPreference(key, value) {
		return axios
			.put(`${process.env.REACT_APP_API_URL}/api/account/preference`,
				{
					key,
					value,
				})
			.then(res => res)
			.catch(logError);
	}
}

export default new UserService();
