import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const ChallengeDetailsPageAsync = Loadable({
	loader: () => import('./challenge-details.page.js'),
	modules: ['./challenge-details.page.js'],
	webpack: () => [require.resolveWeak('./challenge-details.page.js')],
	loading: Loading,
});

export default ChallengeDetailsPageAsync;
