import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const EmailVerificationSuccessModalAsync = Loadable({
	loader: () => import(/* webpackChunkName: "verify-account-email-success" */ './verify-account-email-success.js'),
	modules: ['./verify-account-email-success.js'],
	webpack: () => [require.resolveWeak('./verify-account-email-success.js')],
	loading: Loading,
});

export default EmailVerificationSuccessModalAsync;
