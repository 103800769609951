import React from 'react';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { inject, observer } from 'mobx-react';
import { observe } from 'mobx';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CheckBox } from '../ui-toolkit/inputs.js';
import UserService from '../../shared/user.service.js';
import Utils from '../shared/util.js';
import {
	PrimaryButton,
	SecondaryButton,
} from '../ui-toolkit/buttons.js';
import ModalAlert from '../ui-toolkit/modal.js';
import './switch-profile-modal.scss';


class SwitchProfileModal extends React.Component {
	state = {
		hideModal: false,
		checkboxValue: false,
	};

	componentDidMount() {
		const { intl, authService } = this.props;

		if (authService.isLoggedIn) {
			UserService.getAccountPreference('homepage.redirect.message')
				.then((data) => {
					this.setState({
						hideModal: (data && JSON.parse(data.value)) || false,
					});
				})
				.catch((error) => {
					Utils.showErrorMessageAlert(error, toast.TYPE.ERROR, intl);
				});
		}

		observe(authService, 'isLoggedIn', ({ newValue }) => {
			if (newValue) {
				UserService.getAccountPreference('homepage.redirect.message')
					.then((data) => {
						this.setState({
							hideModal: (data && JSON.parse(data.value)) || false,
						});
					})
					.catch((error) => {
						Utils.showErrorMessageAlert(error, toast.TYPE.ERROR, intl);
					});
			}
		});
	}

	componentDidUpdate() {
		const { userStore, location } = this.props;
		const { hideModal } = this.state;

		if ((location.pathname === '/' || hideModal) && userStore.isSwitchingProfile) {
			this.switchProfile();
		}
	}

	toggleCheckbox = () => {
		this.setState(prevState => ({
			checkboxValue: !prevState.checkboxValue,
		}));
	};

	switchProfile = () => {
		const { userStore, history } = this.props;

		userStore.selectProfile(userStore.targetProfile);
		userStore.switchProfile(false);
		this.updatePreference();
		history.push('/');
	};

	closeModal = () => {
		const { userStore } = this.props;

		userStore.switchProfile(false);
	};

	updatePreference = () => {
		const { intl } = this.props;
		const { checkboxValue } = this.state;

		UserService.updateAccountPreference('homepage.redirect.message', checkboxValue)
			.then(() => (
				this.setState({
					hideModal: checkboxValue,
				})
			))
			.catch((error) => {
				Utils.showErrorMessageAlert(error, toast.TYPE.ERROR, intl);
			});
	};

	render() {
		const { userStore, location, intl } = this.props;
		const { checkboxValue, hideModal } = this.state;

		if (!userStore.isSwitchingProfile || hideModal || (location.pathname === '/' && userStore.isSwitchingProfile)) {
			return null;
		}

		return (
			<ModalAlert isOpen={userStore.isSwitchingProfile} toggle={this.closeModal} className="switch-profile-modal">
				<FormattedMessage id="switch_user.modal.title">
					{children => <h3 className="title">{children}</h3>}
				</FormattedMessage>
				<FormattedMessage id="switch_user.modal.description">
					{children => <p className="description">{children}</p>}
				</FormattedMessage>
				<CheckBox label={intl.formatMessage({ id: 'switch_user.modal.hide' })} value={checkboxValue} onChange={this.toggleCheckbox} />
				<div className="submission">
					<SecondaryButton onClick={this.closeModal} type="button">
						<FormattedMessage id="switch_user.modal.cancel">{children => (children)}</FormattedMessage>
					</SecondaryButton>
					<PrimaryButton onClick={this.switchProfile} type="button">
						<FormattedMessage id="switch_user.modal.submit">{children => (children)}</FormattedMessage>
					</PrimaryButton>
				</div>
			</ModalAlert>
		);
	}
}

export default withRouter(injectIntl(inject('authService', 'userStore')(observer(SwitchProfileModal))));
