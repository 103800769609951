import React from 'react';

import LoadingAnimation from '../assets/images/loading.mp4';

import './loading.scss';

const Loading = () => (
	<div className="loading">
		<video
			width="168"
			height="126"
			preload="auto"
			autoPlay="autoplay"
			loop="loop"
		>
			<source src={LoadingAnimation} type="video/mp4" />
		</video>
	</div>
);

export default Loading;

