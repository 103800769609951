import React from 'react';
import { Switch, withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { observe } from 'mobx';
import { brightAuthService } from 'bright-auth';
import cn from 'classnames';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { CSSTransition } from 'react-transition-group';
import { ToastContainer, Slide } from 'react-toastify';
import { Drift } from 'react-drift';
import { SecuredRoute, AdminSecuredRoute } from './components/shared/with-secured-route.js';
import Utils from './components/shared/util.js';
import ProfileInterceptor from './profile.interceptor.js';
import Navbar from './layout/navbar.component.js';
import Footer from './layout/footer.js';
import Loading from './components/loading.js';
import PageNotFound from './components/not-found/page-not-found.js';
import ErrorBoundary from './components/errors/error-boundary.js';
import LoginPageAsync from './features/login/login.page.async.js';
import UserAccountPageAsync from './features/user-account/user-account.page.async.js';
import UserProfilePageAsync from './features/user-profile/user-profile.page.async.js';
import SignUpPageAsync from './features/registration/sign-up.page.async.js';
import ChallengeListPageAsync from './features/challenges/challenge-list.page.async.js';
import ChallengeDetailsPageAsync from './features/challenges/challenge-details.page.async.js';
import SubmitChallengePageAsync from './features/challenges/submit-challenge.page.async.js';
import SubmitChallengeSolutionPageAsync from './features/challenges/submit-challenge-solution.page.async.js';
import ChallengeSolutionDetailsPageAsync from './features/challenges/challenge-solution-details.page.async.js';
import DashboardPageAsync from './features/dashboard/dashboard.page.async.js';
import ContactsPageAsync from './features/contacts/contacts.page.async.js';
import ContactsMessagesPageAsync from './features/contacts/contacts-messages.page.async.js';
import SolutionsPageAsync from './features/solutions/solutions.page.async.js';
import GetLicensesPageAsync from './features/licenses/get-licenses.page.async.js';
import PaymentSuccessfulPageAsync from './features/payments/payment-successful.page.async.js';
import UserPaymentsPageAsync from './features/user-payments/user-payments.page.async.js';
import RewardsPageAsync from './features/rewards/rewards.page.async.js';
import ActiveAccountEmailModalAsync from './features/verify-account/active-account-email.async.js';
import EmailVerificationModalAsync from './features/verify-account/verify-account-email.async.js';
import EmailVerificationSuccessModalAsync from './features/verify-account/verify-account-email-success.async.js';
import ChangePasswordModalAsync from './features/login/change-password.modal.async.js';
import ChangeProfileWarningModal from './components/switch-profile-modal/switch-profile-modal.js';
import AcceptTermsModalAsync from './features/accept-terms/accept-terms.modal.async.js';
import UnsubscribeModalAsync from './features/unsubscribe/unsubscribe.modal.async.js';
import BackOfficeAsync from './backoffice/backoffice.async.js';

library.add(fab, faCircle);

brightAuthService.init({ apiUrl: process.env.REACT_APP_API_URL });
new ProfileInterceptor();

function isAtEntrancePage(location) {
	return location.pathname.startsWith('/sign-')
		|| location.pathname.startsWith('/active-account')
		|| location.pathname.startsWith('/email-validation')
		|| location.pathname.startsWith('/reset')
		|| location.pathname.startsWith('/admin');
}

class PageTransitionInternal extends React.Component {
	componentDidMount() {
		const { generalStore } = this.props;

		// hide/show drift chat widget
		observe(generalStore, 'hideDriftChat', ({ newValue }) => {
			// eslint-disable-next-line
			const driftChat = window.driftt = window.drift = window.driftt || false;

			if (driftChat) {
				if (newValue) {
					driftChat.on('ready', (api) => {
						api.widget.hide();
						document.getElementById('drift-widget-container').classList.add('d-none');
					});
				} else {
					driftChat.on('ready', (api) => {
						api.widget.show();
						document.getElementById('drift-widget-container').classList.remove('d-none');
					});
				}
			}
		});
	}

	shouldComponentUpdate() {
		return true;
	}

	componentDidUpdate(prevProps) {
		const { generalStore: { startAnimation, isLoading }, location } = this.props;

		if (
			prevProps.location.pathname === location.pathname
			&& prevProps.generalStore.isLoading === isLoading
		) return null;

		startAnimation();

		window.scrollTo({
			top: 0,
		});
	}


	render() {
		const { generalStore: { isLoading, animate, stopAnimation }, children } = this.props;

		return (
			<CSSTransition
				in={animate && !isLoading}
				classNames="fadeTransition"
				timeout={300}
				onEntered={() => {
					stopAnimation();
				}}
			>
				{children}
			</CSSTransition>
		);
	}
}

const PageTransition = withRouter(inject('generalStore')(
	observer(PageTransitionInternal),
));

class App extends React.Component {
	componentDidMount() {
		const { authService, history, userStore } = this.props;

		// when the user logs in, identify him in the drift chat
		observe(userStore, 'user', ({ newValue }) => {
			if (newValue.id) {
				const driftChat = window.driftt = window.drift = window.driftt || false;

				if (driftChat) {
					driftChat.identify(newValue.id, {
						name: newValue.fullName,
						email: newValue.email,
					});
				}
			}
		});

		// when the refresh token expires, redirect to the login page
		observe(authService, 'refreshTokenFailed', ({ newValue }) => {
			if (newValue) {
				userStore.clearProfiles();
				authService.logout();
				Utils.resetDriftChat();
				history.push('/sign-in');
			}
		});
	}

	render() {
		const {
			navbarOpen, location, isLoading, authService, userStore,
		} = this.props;

		return (
			<div className={cn('app-shell', { freeze: navbarOpen })}>
				<ToastContainer
					position="top-center"
					className={cn({ Toastify__simple: isAtEntrancePage(location) })}
					hideProgressBar
					draggable={false}
					closeButton={false}
					transition={Slide}
				/>

				{!isAtEntrancePage(location) && (
					<Navbar />
				)}

				<ChangeProfileWarningModal />

				<ErrorBoundary>
					{isLoading && !authService.refreshTokenFailed && <Loading /> }

					<main className={cn({ 'page-wrapper': !isAtEntrancePage(location) })}>
						<PageTransition>
							<Switch>
								<Route component={ChallengeListPageAsync} exact path="/" />

								<AdminSecuredRoute component={BackOfficeAsync} path="/admin" />

								<Route component={LoginPageAsync} exact path="/sign-in" />

								<Route
									component={LoginPageAsync}
									exact
									path="/sign-in/social-login-success/:token"
								/>

								<Route component={SignUpPageAsync} exact path="/sign-up" />

								<Route
									render={() => <ActiveAccountEmailModalAsync openModal />}
									exact
									path="/active-account"
								/>

								<Route
									render={() => <EmailVerificationModalAsync openModal />}
									exact
									path="/email-validation"
								/>

								<Route
									render={() => <EmailVerificationSuccessModalAsync openModal />}
									exact
									path="/sign-in/email-validation-success/:code"
								/>

								<Route
									render={() => <ChangePasswordModalAsync openModal />}
									exact
									path="/reset/:token"
								/>

								<Route
									render={() => <UnsubscribeModalAsync openModal />}
									exact
									path="/newsletter/unsubscribe/:token"
								/>

								<SecuredRoute component={DashboardPageAsync} path="/dashboard" />

								<SecuredRoute component={ContactsPageAsync} exact path="/contacts" />

								<SecuredRoute component={ContactsMessagesPageAsync} exact path="/messages" />

								<SecuredRoute component={ContactsMessagesPageAsync} exact path="/messages/:userId" />

								<SecuredRoute component={SolutionsPageAsync} exact path="/solutions" />

								<SecuredRoute
									component={SubmitChallengePageAsync}
									exact
									path="/challenges/submit-challenge"
								/>

								<Route
									component={ChallengeDetailsPageAsync}
									exact
									path="/challenges/:challengeId"
								/>

								<SecuredRoute
									component={SubmitChallengePageAsync}
									exact
									path="/challenges/:challengeId/edit"
								/>

								<SecuredRoute
									component={SubmitChallengeSolutionPageAsync}
									exact
									path="/challenges/:challengeId/solution/submit-solution"
								/>

								<SecuredRoute
									component={ChallengeSolutionDetailsPageAsync}
									exact
									path="/challenges/:challengeId/solution/:solutionId"
								/>

								<SecuredRoute
									component={SubmitChallengeSolutionPageAsync}
									exact
									path="/challenges/:challengeId/solution/:solutionId/edit"
								/>

								<SecuredRoute component={UserProfilePageAsync} exact path="/profile" />

								<SecuredRoute component={UserProfilePageAsync} exact path="/profile/:profileId" />

								<SecuredRoute component={UserAccountPageAsync} path="/account" />

								<SecuredRoute component={UserPaymentsPageAsync} path="/payments" />

								<SecuredRoute component={RewardsPageAsync} path="/rewards" />

								<SecuredRoute component={GetLicensesPageAsync} exact path="/get-licenses" />

								<SecuredRoute
									component={PaymentSuccessfulPageAsync}
									exact
									path="/payment-successful"
								/>

								<Route component={PageNotFound} path="/404" exact />

								<Route component={PageNotFound} />
							</Switch>
						</PageTransition>
					</main>

					<AcceptTermsModalAsync
						openModal={(!isLoading && authService.isLoggedIn && !userStore.isFetching) && (!userStore.user.termsAcceptance || !userStore.user.phoneNumber)}
						hasPhoneNumber={!!userStore.user.phoneNumber}
					/>
				</ErrorBoundary>

				{process.env.REACT_APP_DRIFT_ID && (
					<Drift appId={process.env.REACT_APP_DRIFT_ID} />
				)}

				{!isAtEntrancePage(location) && <Footer />}
			</div>
		);
	}
}


const AppShell = inject('authService', 'languageStore', 'userStore', 'generalStore')(
	observer(({
		location, authService, languageStore, userStore, generalStore, history,
	}) => {
		// unwrap the language so it updates when the language is changed
		// eslint-disable-next-line no-unused-vars
		const { language } = languageStore;
		// eslint-disable-next-line no-unused-vars
		const { profile, isFetching, user } = userStore;
		// eslint-disable-next-line no-unused-vars
		const { isLoggedIn } = authService; // to redirect when logout in the app

		const { navbarOpen, isLoading, hideDriftChat } = generalStore;

		return (
			<App
				authService={authService}
				history={history}
				userStore={userStore}
				navbarOpen={navbarOpen}
				location={location}
				isLoading={isLoading}
				hideDriftChat={hideDriftChat}
			/>
		);
	}),
);

export default withRouter(AppShell);
