import Loadable from 'react-loadable';

import Loading from '../../components/loading.js';

const UnsubscribeModalAsync = Loadable({
	loader: () => import(/* webpackChunkName: "unsubscribe-modal" */ './unsubscribe.modal.js'),
	modules: ['./unsubscribe.modal.js'],
	webpack: () => [require.resolveWeak('./unsubscribe.modal.js')],
	loading: Loading,
});

export default UnsubscribeModalAsync;
