import { toast } from 'react-toastify';

let timerPlaceholder = {};

export function debounce(callback, timer) {
	clearTimeout(timerPlaceholder);

	timerPlaceholder = setTimeout(() => {
		callback();
	}, timer);
}

class Utils {
	static getErrorInvalidArgumentMessage(error) {
		 return (error && error.response && error.response.data && error.response.data.code && error.response.data.code.startsWith('INVALID_ARGUMENT')) ? error.response.data.message : null;
	} // TODO remove when the errors go to the back-end

	static getErrorMessageCode(error) {
		return this.getErrorInvalidArgumentMessage(error) || (error && error.response && error.response.data && error.response.data.code) || 'UNKNOWN_101';
	}

	static getErrorMessage(error) {
		return error && error.response && error.response.data && error.response.data.message;
	}

	static isUserNotFoundErrorCode(error) {
		return (error && error.response && error.response.data && error.response.data.code && error.response.data.code === 'USER_101');
	}

	static showErrorStripeMessageAlert(code, type, intl) {
		const stripeErrorsCodeList = [
			'bank_account_unusable',
			'card_declined',
			'customer_max_subscriptions',
			'expired_card',
			'incorrect_cvc',
			'incorrect_number',
			'invalid_card_type',
			'invalid_cvc',
			'invalid_expiry_month',
			'invalid_expiry_year',
			'invalid_number',
			'api_connection_error',
			'invalid_request_error',
		];

		const errorCode = stripeErrorsCodeList.some(s => s === code)
			?	`error.stripe.${stripeErrorsCodeList.find(s => s === code)}` : 'error.stripe.generic';

		const message = intl.formatMessage({ id: errorCode });

		this.showMessageAlert(errorCode, type, message);
	}

	static showErrorMessageAlert(error, type, intl) {
		const errorCode = Utils.getErrorMessageCode(error);
		const message = intl.formatMessage({ id: Utils.getErrorMessageCode(error) });

		if (!(error && error.response && error.response.status && error.response.status === 401)) {
			this.showMessageAlert(errorCode, type, message);
		}
	}

	static showMessageAlert(code, type, message) {
		setTimeout(() => {
			if (toast.isActive(code)) {
				toast.update(code, {
					render: message,
					toastId: code,
					autoClose: 5000,
				});
			} else {
				toast(message, { toastId: code, type });
			}
		});
	}

	static validateEmail(email) {
		const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

		return emailRegex.test(email);
	}

	static validateFloatingNumber(number) {
		const floatRegex = /^d+(\.\d+)?$/;
		return floatRegex.test(number);
	}

	static importAllFileIcons(filesImportContext) {
		return filesImportContext.keys().reduce((obj, item) => {
			// eslint-disable-next-line no-param-reassign
			obj[item.replace('./', '')] = filesImportContext(item);
			return obj;
		}, {});
	}

	static calcVATPrice(price, vatPercentage) {
		return (price * vatPercentage) / 100;
	}

	static roundToDecimal(num, decimalPlaces) {
		return Number(num.toFixed(decimalPlaces));
	}

	static formatNumberWithSpace(num) {
		return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
	}

	static formatNumberCurrency(number, currencyCode = 'EUR', languageCode) {
	 	return new Intl.NumberFormat(languageCode,
			{ style: 'currency', currency: currencyCode, minimumFractionDigits: 0 }).format(number);
	}

	static getNameInitials(name) {
		const nameSplit = name.split(' ');
		let initials = '';

		if (nameSplit.length === 1) {
			initials = nameSplit[0] ? nameSplit[0].charAt(0) : '?';
		} else {
			initials = nameSplit[0].charAt(0) + nameSplit[nameSplit.length - 1].charAt(0);
		}

		return initials.toUpperCase();
	}

	static getOrganisationSizeOptions() {
		return [
			{ value: 'MICRO', label: '1 - 10' },
			{ value: 'SMALL', label: '10 - 50' },
			{ value: 'MEDIUM', label: '50 - 250' },
			{ value: 'LARGE', label: '>= 250' },
		];
	}

	static getTypeImageFilesValid() {
		return ['.jpg', '.jpeg', '.png', '.gif'];
	}

	static getAllTypeFilesValid() {
		return ['.pdf', '.doc', '.ppt', '.xls', '.docx', '.pptx', '.xlsx', '.jpg', '.jpeg', '.png', '.gif'];
	}

	static capitalizeString(string) {
		if (typeof string !== 'string') return '';
		return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
	}

	static stringToKey(string) {
		return string.replace(/./g, '_').split(' ').join('_');
	}

	static getArrayOfYearsDesc(startYear) {
		const years = [new Date().getFullYear()];
		let currentYear = new Date().getFullYear();
		while (currentYear > startYear) years.push(currentYear -= 1);
		return years;
	}

	static validateCreditCard(ccNum) {
		const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
		const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
		const amexpRegEx = /^(?:3[47][0-9]{13})$/;
		const discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;

		const number = parseInt(ccNum.replace(/\s/g, ''), 10);

		if (visaRegEx.test(number)
			|| mastercardRegEx.test(number)
			|| amexpRegEx.test(number)
			|| discovRegEx.test(number)) {
			return true;
		}
		return false;
	}

	static ValidateExpirationDate = (expirationDate) => {
		const exp = new Date(expirationDate);
		let today = new Date();
		const mm = today.getMonth() + 1;
		const yy = today.getFullYear() - 2000;
		today = `${mm}/${yy}`;

		if (
			// expirationDate < today
			exp.getMonth() + 1 > 12
			|| exp.getMonth() + 1 < 0
			|| exp.length < 4
			|| !exp.getMonth()
		) {
			return false;
		}

		return true;
	};

	static emailSplit = (email) => {
		const delimiterIndex = email.indexOf('@');

		if (delimiterIndex === -1) {
			return null;
		}

		return {
			local: email.slice(0, delimiterIndex),
			domain: email.slice(delimiterIndex + 1),
		};
	};


	static ValidateCVC = cvc => (!!((cvc > 99 && cvc < 1000)));

	static resetDriftChat = () => {
		const driftChat = window.driftt = window.drift = window.driftt || false;

		if (driftChat) {
			driftChat.reset();
		}
	};
}

export default Utils;
