import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import cn from 'classnames';
import Media from 'react-media';

import modalClose from '../../assets/images/reset-password/close-modal.svg';

import './modal.scss';


const externalCloseBtn = (
	<img className="modal-close" src={modalClose} alt="close" />
);

const ModalAlert = ({
	isOpen,
	toggle,
	onClosed,
	centered,
	children,
	className,
	backdropClassName,
	fade,
	showClose,
	backdrop,
	keyboard,
}) => (
	<Media query="(max-width: 575.98px)">
		{matches => (
			<Modal
				isOpen={isOpen}
				toggle={toggle}
				onClosed={onClosed}
				className={cn({ 'modal-dialog-centered': centered }, className)}
				external={matches && showClose && externalCloseBtn}
				backdropClassName={backdropClassName}
				fade={fade}
				zIndex={2040}
				backdrop={backdrop}
				keyboard={keyboard}
			>
				<ModalBody>{children}</ModalBody>
			</Modal>
		)}
	</Media>
);

export default ModalAlert;
