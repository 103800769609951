import Loadable from 'react-loadable';

import Loading from '../components/loading.js';

const BackOfficeAsync = Loadable({
	loader: () => import(/* webpackChunkName: "backoffice" */ './index.js'),
	modules: ['./index.js'],
	webpack: () => [require.resolveWeak('./index.js')],
	loading: Loading,
});

export default BackOfficeAsync;
