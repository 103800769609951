import React, { Component } from 'react';
import { NavLink as Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { injectIntl } from 'react-intl';
import { inject, observer } from 'mobx-react';
import * as SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import Utils from '../components/shared/util.js';
import ContactsService from '../features/contacts/contacts.service.js';
import messagesIcon from '../assets/images/notifications/messages.svg';
import './chat-notifications.component.scss';

class ChatNotifications extends Component {
	constructor(props) {
		super(props);
		this.stompClient = null;
	}

	componentDidMount() {
		const { authService, generalStore } = this.props;

		Promise.all([
			ContactsService.getUnreadMessages(),
			ContactsService.getAnonymousUnreadMessages(),
		])
			.then(([unreadMessages, anonymousUnreadMessages]) => {
				if (unreadMessages.length || anonymousUnreadMessages.length) {
					generalStore.storeHasUnreadMessages(true);
				}
			})
			.catch((error) => {
				const { intl } = this.props;
				Utils.showErrorMessageAlert(error, toast.TYPE.ERROR, intl);
			});


		const ws = new SockJS(`${process.env.REACT_APP_API_URL}/ws?access_token=${authService.getAccessToken()}`);
		this.stompClient = Stomp.over(ws);
		this.stompClient.debug = null;

		this.stompClient.connect({}, this.onConnected);
	}

	onConnected = () => {
		this.stompClient.subscribe('/user/queue/notifications', this.onMessageReceived);
	};

	onMessageReceived = () => {
		const { generalStore } = this.props;
		generalStore.storeHasUnreadMessages(true);
	};

	render() {
		const { generalStore: { hasUnreadMessages } } = this.props;

		return (
			<div className="notifications-wrapper">
				<Link to="/messages">
					<div className="message-notifications">
						<img src={messagesIcon} alt="" />
						{hasUnreadMessages && <div className="unread" />}
					</div>
				</Link>
			</div>
		);
	}
}

export default inject('authService', 'generalStore')(injectIntl(observer(ChatNotifications)));
