import axios from 'axios';

function logError(error) {
	console.error(error);
	throw error;
}

class LayoutService {
	getGeneralData(language) {
		return axios
			.get(
				`${
					process.env.REACT_APP_CMS_URL
				}/api/singletons/get/business?lang=${language}`,
			)
			.then(res => res)
			.catch(logError);
	}

	getContactsData(language) {
		return axios
			.post(
				`${
					process.env.REACT_APP_CMS_URL
				}/api/singletons/get/contacts?lang=${language}`,
				{ populate: 1 },
			)
			.then(res => res.data)
			.catch(logError);
	}
}

export default new LayoutService();
