import React, {
	Component,
	Fragment,
} from 'react';
import { withRouter } from 'react-router';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage } from 'react-intl';
import {
	Popover,
	PopoverBody,
} from 'reactstrap';

import cn from 'classnames';

import Utils from '../shared/util.js';
import { ReactComponent as FilterIconLogo } from '../../assets/images/filter/filters.svg';
import { ReactComponent as BackLogo } from '../../assets/images/back_home.svg';
import { ReactComponent as FacebookIcon } from '../../assets/images/footer/ic-facebook.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/images/footer/ic-linkedin.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/footer/ic-twitter.svg';
import { ReactComponent as EmailIcon } from '../../assets/images/ic-email.svg';
import { ReactComponent as CopyIcon } from '../../assets/images/ic-link.svg';

import './buttons.scss';

const PrimaryButton = ({
	id,
	type,
	disabled,
	children,
	fullWidth,
	outline,
	small,
	big,
	className,
	onClick,
}) => (
	<button
		id={id}
		type={type}
		onClick={onClick}
		disabled={disabled}
		className={cn(
			'primary-button',
			{
				'w-100': fullWidth,
			},
			{
				'outline-button': outline,
			},
			{
				small,
			},
			{
				big,
			},
			className,
		)}
	>
		{children}
	</button>
);

const SecondaryButton = ({
	type, disabled, fullWidth, small, className, children, onClick,
}) => (
	<button
		type={type}
		onClick={onClick}
		disabled={disabled}
		className={cn(
			'secondary-button',
			{
				'w-100': fullWidth,
			},
			{
				small,
			},
			className,
		)}
	>
		{children}
	</button>
);

const TertiaryButton = ({
	type,
	disabled,
	fullWidth,
	outline,
	big,
	small,
	onClick,
	className,
	children,
}) => (
	<button
		type={type}
		onClick={onClick}
		disabled={disabled}
		className={cn(
			'tertiary-button',
			{
				'w-100': fullWidth,
			},
			{
				'outline-button': outline,
			},
			{
				big,
			},
			{
				small,
			},
			className,
		)}
	>
		{children}
	</button>
);

const FilterButton = ({
	id, type, disabled, children, icon, onClick, isFiltered,
}) => (
	<button
		id={id}
		type={type}
		onClick={onClick}
		disabled={disabled}
		className={cn('filter-button', {
			'has-icon': icon,
			isFiltered,
			normal: !isFiltered,
		})}
	>
		{children}
		{icon && (
			<div className="button-icon-wrapper">
				<FilterIconLogo alt="" />
			</div>
		)}
	</button>
);

const OvalButton = ({
	id, type, disabled, onClick, children, className,
}) => (
	<button
		id={id}
		type={type}
		onClick={onClick}
		disabled={disabled}
		className={cn(
			'oval-button',
			className,
		)}
	>
		{children}
	</button>
);

const ClearButton = ({
	type, disabled, onClick, children, className,
}) => (
	<button
		type={type}
		onClick={onClick}
		disabled={disabled}
		className={cn(
			'clear-button',
			className,
		)}
	>
		{children}
	</button>
);

const UnderlineButton = ({
	type, disabled, onClick, children,
}) => (
	<button
		type={type}
		onClick={onClick}
		disabled={disabled}
		className="underline-button"
	>
		{children}
	</button>
);


class DownloadButton extends React.Component {
	fileIcons = Utils.importAllFileIcons(
		require.context('../../assets/images/file-icons', false, /\.svg$/),
	);

	render() {
		const {
			downloading, downloadProgress, type, onClick, disabled, fileExtension, fileName, children,
		} = this.props;

		return (
			<button
				style={{ background: downloading ? `linear-gradient(to right, #e8e9ec ${downloadProgress}%, transparent 0` : 'inherit' }}
				type={type}
				onClick={onClick}
				disabled={disabled}
				className="download-button"
			>
				<img
					className="file-icon"
					src={
						this.fileIcons[`${fileExtension}.svg`]
							? this.fileIcons[`${fileExtension}.svg`]
							: this.fileIcons['default-icon.svg']
					}
					alt={fileName}
					width="19"
					height="26"
				/>

				{children}
			</button>
		);
	}
}

const RoundButton = ({
	primary, children, className, small,
}) => (
	<div className={cn(className, 'round-button', { primary }, { small })}>
		{children}
	</div>
);

const LinkedinButton = ({
	disabled,
	children,
	fullWidth,
	big,
	icon,
	urlAction,
}) => (
	<form method="POST" action={urlAction}>
		<button
			type="submit"
			disabled={disabled}
			className={cn(
				'linkedin-button',
				{
					'w-100': fullWidth || false,
				},
				{
					big,
				},
			)}
		>
			{icon && (
				<div className="button-icon-wrapper">
					<div className="button-icon">
						{icon}
					</div>
				</div>
			)}
			{children}
		</button>
	</form>
);

const PrimaryButtonFull = ({
	type,
	disabled,
	children,
	onClick,
	className,
}) => (
	<button
		type={type}
		onClick={onClick}
		disabled={disabled}
		className={`btn-inc btn-inc-primary ${className}`}
	>
		{children}
	</button>
);

const SecondaryButtonFull = ({
	type,
	disabled,
	children,
	onClick,
}) => (
	<button
		type={type}
		onClick={onClick}
		disabled={disabled}
		className="btn-inc btn-inc-secondary"
	>
		{children}
	</button>
);


const BackHomePageButton = ({ children, onClick }) => (
	<button type="button" className="back-homepage-btn" onClick={onClick}>
		<BackLogo />
		{children}
	</button>
);

class SocialShareButtonInternal extends Component {
	state = {
		popoverOpen: false,
		value: process.env.REACT_APP_PUBLIC_URL + this.props.location.pathname,
	};

	toggle = () => {
		const { popoverOpen } = this.state;
		this.setState({
			popoverOpen: !popoverOpen,
		});
	};

	render() {
		const { type } = this.props;
		const { value, popoverOpen } = this.state;

		switch (type) {
		case 'facebook':
			return (
				<a
					className="social-share-button facebook"
					target="_blank"
					rel="noopener noreferrer"
					href={`https://www.facebook.com/sharer/sharer.php?u=${value}`}
				>
					<FacebookIcon />
				</a>
			);
		case 'linkedin':
			return (
				<a
					className="social-share-button linkedin"
					target="_blank"
					rel="noopener noreferrer"
					href={`https://www.linkedin.com/shareArticle?mini=true&url=${value}&title=&summary=&source=`}
				>
					<LinkedinIcon />
				</a>
			);
		case 'twitter':
			return (
				<a
					className="social-share-button twitter"
					target="_blank"
					rel="noopener noreferrer"
					href={`https://twitter.com/home?status=${value}`}
				>
					<TwitterIcon />
				</a>
			);
		case 'email':
			return (
				<a
					className="social-share-button email"
					target="_blank"
					rel="noopener noreferrer"
					href={`mailto:?body=${value}`}
				>
					<EmailIcon />
				</a>
			);
		case 'copy':
			return (
				<Fragment>
					<Popover
						placement="bottom"
						isOpen={popoverOpen}
						target="copy-url"
						toggle={this.toggle}
						className="copy-url-popover"
					>
						<PopoverBody>
							<FormattedMessage id="challenge.social_links.copied" />
						</PopoverBody>
					</Popover>
					<CopyToClipboard
						text={value}
						onCopy={() => {
							this.setState({ popoverOpen: true });
							setTimeout(() => {
								this.setState({ popoverOpen: false });
							}, 2000);
						}}
					>
						<button id="copy-url" className="social-share-button copy" type="button">
							<CopyIcon />
						</button>
					</CopyToClipboard>
				</Fragment>
			);
		default:
			return null;
		}
	}
}

const SocialShareButton = withRouter(SocialShareButtonInternal);


export {
	PrimaryButton,
	SecondaryButton,
	TertiaryButton,
	FilterButton,
	ClearButton,
	UnderlineButton,
	OvalButton,
	DownloadButton,
	RoundButton,
	LinkedinButton,
	PrimaryButtonFull,
	SecondaryButtonFull,
	BackHomePageButton,
	SocialShareButton,
};
